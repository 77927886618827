import { Component, OnInit, ViewChild, OnDestroy, Injectable } from '@angular/core';
import { DropdownModule, AutoCompleteModule, SelectItem, InplaceModule, MenuItem, ConfirmationService } from 'primeng/primeng';
import {
  HttpClient,
  UserDto,
  Authentication,
  AuthGuard,
  AlertService,
  InfoService,
  Configuration
} from '../../../../helper';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
//import * as mdb from 'mdbootstrap';
import { ViewEncapsulation } from '@angular/compiler/src/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// import { BrowserQRCodeReader, VideoInputDevice } from 'zxing-typescript/src/browser/BrowserQRCodeReader'
import QRCode from 'qrcode';
// import { ImageViewerConfig } from 'ngx-image-viewer';

// import { JsBarcode } from 'JsBarcode';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
  selector: "app-reservasi-online",
  templateUrl: "./reservasi-online.component.html",
  styleUrls: ["./reservasi-online.component.scss"],
  // encapsulation: ViewEncapsulation.None
  providers: [ConfirmationService],
})
export class ReservasiOnlineComponent implements OnInit {
  loading: boolean = false;
  isShow: boolean = false;
  isHide: boolean = true;
  statuslist: boolean = true;
  isTelemedicine: boolean = false
  isBaru: boolean = false
  isLama: boolean = false
  isLamaNotLengkap: boolean = false //ash

  isSlot: boolean = true
  minDate: any = new Date(new Date().setDate(new Date().getDate()));
  minDateTelemedicine: any = new Date(
    new Date().setDate(new Date().getDate() + 1)
  );
  listRujukan: any[] = [];
  ListisBPJSnorujukan: boolean = false;
  NotisBPJS: boolean = false;

  // minDate = new Date(new Date().setDate(new Date().getDate() - 1))
  maxDate: any;
  model: any = {};
  stepsMenuItems: MenuItem[];
  stepsMenuItemsTelemedicine: MenuItem[];
  activeIndex: number = 0;
  listJK: any[] = []
  listRuangan: any[] = []
  listDokter: any = []
  listDokterTemp: any = []
  listTipePembayaran: any[] = []
  listTipePembayaranTelemedicine: any[] = []
  listnamaPenjamin: any[] = []
  listAsalRujukan: any[] = []

  //-- ash 26-06-2023
  listAgama: any[] = []
  listKebangsaan: any[] = []
  listNegara: any[] = []
  listStatusPerkawinan: any[] = []
  listPendidikan: any[] = []
  listPekerjaan: any[] = []
  listSuku: any[] = []
  listKelurahan: any[] = []
  namakelurahan: any;
  items: SelectItem[];
  //-----------------


  tglInput:any;
  listDokterLibur: any;
  dataDokter: any;
  tglRujukan: any
  tanggalRujukan : any
  isSelected1: any;
  isSelected2: any;
  formGroupTelemedicine: FormGroup;
  formGroup: FormGroup;
  //formGroupNot: FormGroup; //ash
  formHistory: FormGroup;
  formPelayanan: FormGroup;
  myControl = new FormControl();
  isLoading: boolean;
  isAsuransi: boolean = false;
  filteredOptions: Observable<string[]>;
  items2: MenuItem[];
  activeItem: MenuItem;
  loadingHistory: boolean = false;
  @ViewChild("menuItems") menu: MenuItem[];
  dataSource: any[];
  listJenisPasien: any[] = [
    {
      id: 1,
      jenis: "Baru",
      src: "assets/humastemplate/images/pasien-baru1a.png",
      style: "kanan",
      images: "ex-image-a",
    },
    {
      id: 2,
      jenis: "Lama",
      src: "assets/humastemplate/images/pasien-lama.png",
      style: "",
      images: "ex-image-b",
    },
  ];
  // listJenisPasien: any[] = [{ 'id': 2, 'jenis': 'Lama', 'src': 'assets/humastemplate/images/pasien-lama.png', 'style': '', 'images': 'ex-image-b' }]
  selection: any = {};
  imageIndex: any = 0;
  // imagesDenah: any="assets/img/denah_rs.jpg";
  images = [
    "assets/img/logodokkes.png",
    // 'https://i.ytimg.com/vi/nlYlNF30bVg/hqdefault.jpg',
    // 'https://www.askideas.com/media/10/Funny-Goat-Closeup-Pouting-Face.jpg'
  ];
  displayDialog: boolean = false;
  kodeReservasi: any;
  tglReservasi: any;
  tglAwal: any;
  tglAkhir: any;
  poliTujuan: any;
  notelp: any;
  dokter: any;
  qrCode: any;
  isBuktiReservasi: boolean = false;
  isBuktiReservasiAsuransi: boolean = false;
  isBuktiReservasiUmum: boolean = false;
  isBuktiReservasiEdit: boolean = false;
  

  //ash 2023-09-17
  isBpjsDat: boolean = false;
  selected2: any;
  poliklinikedit: any;


  namaPasienCetak: any;
  noantrian: any;
  // displayDialog: boolean;
  sortOptions: SelectItem[];
  sortKey: string;
  sortField: string;
  sortOrder: number;
  isCollalsedPasien: boolean = true;
  displayPopUpQr: boolean = false;
  linkBarcode = "https://barcode.tec-it.com/barcode.ashx?data=";
  barCode: any;
  linkBarcode2 = "&code=Code128&dpi=96&dataseparator=";
  listJam: any = [];
  loadSlot: boolean = false;
  dataSlot: any[];
  disableSelect = new FormControl(false);
  isBPJS: boolean = false;
  isSloting2: boolean = false;
  myFilter: any;
  maxJamReservasi: string;
  statusBridging: string;
  rentangReservasi: any;
  listHari = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
  simpanVisible: boolean = true;
  isShowRiwayatPelayanan: boolean;
  item: any = {};
  isShowRincian: boolean = true;
  dataSourceRincian: any[];
  rowGroupMetadata: any;
  totalTagihan: any;
  totalTerbilang: any;
  deposit: any;
  totalBayar: any;
  sisaTagihan: any;
  noBpjs: any;
  selectedItemGridRiwayat: any;
  displayDialogRM: boolean;
  dataSourceRm: any[];
  totalKlaim: any;
  isShowRiwayatRegis: boolean;
  dataSourceBank: any[];
  selectedNorek: any;
  titleKlaim: any = "Total Klaim";
  statusRujukanBerlaku: any = true;
  isPanduanReservasi: boolean;
  itemsPanduan: MenuItem[];
  isDukcapil: boolean;
  imageIndexOne = 0;
  imageIndexTwo = 0;
  statusReservasi: any;
  keteranganReservasi: any;

  
  showtglreservasi: boolean =true;
  showtglasalrujukan: boolean =false;
  showtipepembayaran: boolean =false;
  shownotelpon: boolean =false;
  showpolikinik: boolean =false;
  showdokter: boolean =false; 

  config: ImageViewerConfig = {
    btnClass: "default", // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: "#ccc", // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: true, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
    allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
    btnIcons: {
      // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: "fa fa-plus",
      zoomOut: "fa fa-minus",
      rotateClockwise: "fa fa-repeat",
      rotateCounterClockwise: "fa fa-undo",
      next: "fa fa-arrow-right",
      prev: "fa fa-arrow-left",
      fullscreen: "fa fa-arrows-alt",
    },
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: false,
      rotateCounterClockwise: false,
      next: false,
      prev: false,
    },
  }; // {customBtns: [{name: 'print', icon: 'fa fa-print'}, {name: 'link', icon: 'fa fa-link'}]};

  handleEvent(event: CustomEvent) {
    console.log(`${event.name} has been click on img ${event.imageIndex + 1}`);

    switch (event.name) {
      case "print":
        console.log("run print logic");
        break;
    }
  }
  constructor(
    private httpService: HttpClient,
    private messageService: AlertService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder
  ) {}

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  ngOnInit() {
    // var ssn = document.getElementById('ssn');
    var ssn = <HTMLInputElement>document.getElementById("ssn");

    // ssn.addEventListener('input', this.ssnMask, false);
    // var ssnFirstFive = "";
    // var secondHalf = "";
    // var fullSSN = "";

    let namafield = "isRentangReservasi";
    this.httpService
      .get("medifirst2000/sysadmin/settingdatafixed/get/" + namafield)
      .subscribe(
        (resp) => {
          this.maxDate = new Date(
            new Date().setDate(new Date().getDate() + 100)
          );
        },
        (error) => {
          this.maxDate = new Date(
            new Date().setDate(new Date().getDate() + 100)
          );
        }
      );

    //
    this.httpService
      .get("medifirst2000/reservasionline/get-libur")
      .subscribe((e) => {
        let arr = [];
        if (e.libur.length > 0) {
          for (let i = 0; i < e.libur.length; i++) {
            const element = e.libur[i];
            arr.push(element.tgllibur);
          }
          this.myFilter = (d): boolean => {
            const day: number = d._d.getDay();

            for (let i = 0; i < arr.length; i++) {
              const element = arr[i];
              if (moment(d._d).format("YYYY-MM-DD") == element) {
                return false;
              }
            }

            // Prevent Saturday and Sunday from being selected.
            return day != 0 && day != 6 ? true : true;
          };
        }
        this.getMaxJamReservasis();
        this.getStatusBridging();
        this.loadNoRek();
        //['2019-05-30', '2019-05-31', '2019-06-01', '2019-06-03', '2019-06-04', '2019-06-05', '2019-06-06', '2019-06-07']
      });

    // $("#barcode").JsBarcode("Hi!");
    // $("#barcode").barcode("1234567890128", "ean13");

    // JsBarcode("#barcode", "Hi world!");
    // this.carService.getCarsLarge().then(cars => this.cars = cars);

    // $('#input_starttime').pickatime({
    //   // Light or Dark theme
    //   darktheme: true
    //   });
    this.items2 = [
      { label: "Reservasi", icon: "fa fa-fw fa-calendar" },
      { label: "History", icon: "fa fa-fw fa-history" },
      { label: "Slotting", icon: "fa fa-fw fa fa-calendar-check-o" },
      { label: "E-Billing", icon: "fa fa-fw fa-heartbeat" },
      // { label: 'Bank Account', icon: 'fa fa-fw fa fa-bank' },
      { label: "Panduan", icon: "fa fa-fw fa-question-circle" },
      { label: "Denah", icon: "fa fa-fw fa-map-marker" },
      // { label: 'Telemedicine', icon: 'fa fa-fw fa-mobile' },
    ];

    this.activeItem = this.items2[0];
    this.formGroup = this.fb.group({
      'namaPasien': new FormControl(null),
      'jenisKelamin': new FormControl(null),
      'tglLahir': new FormControl(null),
      'noTelpon': new FormControl(null),
      'noCm': new FormControl(null),
      'tglLahirLama': new FormControl(null),
      'dokter': new FormControl(null),
      'poliKlinik': new FormControl(null),
      'tipePembayaran': new FormControl(null),
      'namaPenjamin': new FormControl(null),
      'tglReservasi': new FormControl({ value: null, disabled: true }),
      'nokartuBpjs': new FormControl(null),
      'noKartuPeserta': new FormControl(null),
      'jamReservasi': new FormControl(null),
      'noRujukan': new FormControl(null),
      'tglRujukan': new FormControl(null),
      'resumeNoRM': new FormControl({ value: null, disabled: true }),
      'resumetglRujukan':new FormControl(null),
      'resumeNama': new FormControl({ value: null, disabled: true }),
      'resumeNamaDukcapil': new FormControl({ value: null, disabled: true }),
      'resumeTglLahir': new FormControl({ value: null, disabled: true }),
      'resumeJK': new FormControl({ value: null, disabled: true }),
      'resumeNoTelp': new FormControl({ value: null, disabled: true }),
      'resumeTglReservasi': new FormControl({ value: null, disabled: true }),
      'resumePoli': new FormControl({ value: null, disabled: true }),
      'resumeDokter': new FormControl({ value: null, disabled: true }),
      'resumeTipe': new FormControl({ value: null, disabled: true }),
      'resumNoka': new FormControl({ value: null, disabled: true }),
      'tanggalRujukan': new FormControl(null),
      'resumNoRujukan': new FormControl({ value: null, disabled: true }),
      'tglReservasiFix': new FormControl(null),
      'isBaru': new FormControl(null),
      'isTelemedicine': new FormControl(null),
      'tglAwal': new FormControl(null),
      'tglAkhir': new FormControl(null),
      'nik': new FormControl(null),
      'resumeNIK': new FormControl({ value: null, disabled: true }),
      'namaPasienDukcapil': new FormControl(null),
      'asalRujukan': new FormControl(null),

      'agama': new FormControl(null),
      'tempatLahir': new FormControl(null),
      'kebangsaan': new FormControl(null),
      'negara': new FormControl(null),
      'statusPerkawinan': new FormControl(null),
      'pendidikan': new FormControl(null),
      'pekerjaan': new FormControl(null),
      'suku': new FormControl(null),
      'kodePos': new FormControl(null),
      'kelurahan': new FormControl(null),
      'alamat': new FormControl(null),
      'isNotLengkap': new FormControl(null),

    });

    this.formGroupTelemedicine = this.fb.group({
      'namaPasien': new FormControl(null),
      'jenisKelamin': new FormControl(null),
      'tglLahir': new FormControl(null),
      'noTelpon': new FormControl(null),
      'domisili': new FormControl(null),
      'noCm': new FormControl(null),
      'tglLahirLama': new FormControl(null),
      'dokter': new FormControl(null),
      'poliKlinik': new FormControl(null),
      'tipePembayaranTelemedicine': new FormControl(null),
      'tglReservasi': new FormControl({ value: null, disabled: true }),
      'nokartuBpjs': new FormControl(null),
      'noKartuPeserta': new FormControl(null),
      'jamReservasi': new FormControl(null),
      'noRujukan': new FormControl({ value: null, disabled: true }),
      'resumeNoRM': new FormControl({ value: null, disabled: true }),
      'resumeNama': new FormControl({ value: null, disabled: true }),
      'resumetglRujukan':new FormControl(null),
      'tanggalRujukan': new FormControl(null),
      'resumeNamaDukcapil': new FormControl({ value: null, disabled: true }),
      'resumeTglLahir': new FormControl({ value: null, disabled: true }),
      'resumeJK': new FormControl({ value: null, disabled: true }),
      'resumeNoTelp': new FormControl({ value: null, disabled: true }),
      'resumeDomisili': new FormControl({ value: null, disabled: true }),
      'resumeTglReservasi': new FormControl({ value: null, disabled: true }),
      'resumePoli': new FormControl({ value: null, disabled: true }),
      'resumeDokter': new FormControl({ value: null, disabled: true }),
      'resumeTipe': new FormControl({ value: null, disabled: true }),
      'resumNoka': new FormControl({ value: null, disabled: true }),
      'resumNoRujukan': new FormControl({ value: null, disabled: true }),
      'tglReservasiFix': new FormControl(null),
      'isBaru': new FormControl(null),
      'isTelemedicine': new FormControl(null),
      'tglRujukan': new FormControl(null),
      'tglAwal': new FormControl(null),
      'tglAkhir': new FormControl(null),
      'nik': new FormControl(null),
      'resumeNIK': new FormControl({ value: null, disabled: true }),
      'namaPasienDukcapil': new FormControl(null),


    });




    this.sortOptions = [
      { label: "Newest First", value: "!year" },
      { label: "Oldest First", value: "year" },
      { label: "Brand", value: "brand" },
    ];
    this.formHistory = this.fb.group({
      noCm: new FormControl(null),
      namaPasien: new FormControl({ value: null, disabled: true }),
      tglLahir: new FormControl({ value: null, disabled: false }),
      tglLahir2: new FormControl(null),
      noTelpon: new FormControl({ value: null, disabled: true }),
      alamat: new FormControl({ value: null, disabled: true }),
      noAsuransi: new FormControl({ value: null, disabled: true }),
      jenisKelamin: new FormControl({ value: null, disabled: true }),
      pendidikan: new FormControl({ value: null, disabled: true }),
      pekerjaan: new FormControl({ value: null, disabled: true }),
      tempatLahir: new FormControl({ value: null, disabled: true }),
      noBPJS: new FormControl({ value: null, disabled: true }),
      nik: new FormControl({ value: null, disabled: true }),
      sortKey: new FormControl(null),
    });

    this.formPelayanan = this.fb.group({
      noRegistrasi: new FormControl(null),
      noRm: new FormControl(null),
    });
    this.setSourceStepMenu();
    this.setSourceStepMenuTelemedicine();
    this.setSourceCombo();

    // this.formGroup.controls['tipePembayaran'].valueChanges.subscribe(
    //   (selectedValue) => {
    //     if (selectedValue.id == 2) {
    //       // this.isAsuransi = false
    //       this.isBPJS = true

    //     } else {
    //       // this.isAsuransi = true
    //       this.isBPJS = false

    //     }
    //   }
    // );
    // var ssn = document.getElementById('idNamaPasien');
    // ssn.addEventListener('input', this.ssnMask(ssn), false);
  }


  //selectedKelurahan: any;

  filteredDataKelurahan: any[] | undefined;

  filterKelurahan(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    this.httpService
      .get(
        "medifirst2000/reservasionline/get-kelurahan?namakelurahan=" + query 
      )
      .subscribe(
        (e) => {
          e.kelurahan.forEach(element => {
            //this.listKelurahan.push(element)
            
            filtered.push(element);
          });

          /*for (let i = 0; i < (this.listKelurahan as any[]).length; i++) {
            let country = (this.listKelurahan as any[])[i];
            if (country.kelurahan.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    
                filtered.push(country);
            }
          }*/
      
          this.filteredDataKelurahan = filtered;
        },
        (error) => {}
      );

    
  }

  ssnMask() {
    var ssn = <HTMLInputElement>document.getElementById("ssn");

    if (ssn.value.length <= 5) {
      ssn.type = "password";
    } else {
      var ssnFirstFive = "";
      var secondHalf = "";
      var fullSSN = "";
      this.detectChanges(ssn);
      secondHalf = ssn.value.substring(5);
      ssn.type = "text";
      ssn.value = "•••••";
      ssn.value += secondHalf;
      fullSSN = ssnFirstFive + secondHalf;
    }
  }

  detectChanges(ssn) {
    var ssnFirstFive = "";
    var secondHalf = "";
    var fullSSN = "";
    for (var i = 0; i < 5; i++) {
      if (ssn.value[i] != "•") {
        ssnFirstFive =
          ssnFirstFive.substring(0, i) +
          ssn.value[i] +
          ssnFirstFive.substring(i + 1);
      }
    }
  }
  getNik() {
    
    const now = new Date();
    let tgls=moment(now).format('YYYY-MM-DD');

    let nik = this.formGroup.get("nik").value;
    if (nik != null && nik.length > 10) this.isDukcapil = false;

    
    this.httpService
      .get("medifirst2000/bridging/bpjs2/get-nik?nik=" + nik +"&tglsep=" + tgls)
      .subscribe(
        (e) => {

    /*
    this.httpService
      .get("medifirst2000/bridging/dukcapil/get-nik-indo/" + nik)
      .subscribe(
        (e) => {
          */
          // this.httpService.get('medifirst2000/bridging/dukcapil/get-nik/' + nik).subscribe(e => {
          //if (e.content == undefined) {
          if (e.response == undefined) {  
            return;
          }
          if (e.messages) {
            this.messageService.error("Error", e.messages);
            return;
          }
          //if (e.content[0] != "") {
          if (e.response != "") {  
            //let result = e.content[0];
            let result = e.response.peserta;
            //let namaPasien = result.NAMA_LGKP;
            let namaPasien = result.nama;

            let subStr = namaPasien.substring(0, 5);
            this.isDukcapil = true;
            // this.formHistory.get('namaPasien').setValue(subStr + ' ********')
            //this.formGroup.get("namaPasien").setValue(result.NAMA_LGKP);
            this.formGroup.get("namaPasien").setValue(result.nama);
            this.formGroup.get("nokartuBpjs").setValue(result.noKartu);
            if(result.noKartu) {
              this.formGroup.get("noKartuPeserta").setValue(result.noKartu);
            } else {
              this.formGroup.get("noKartuPeserta").setValue(this.formGroup.get("nokartuBpjs").value());
            }
            this.formGroup.get("tglLahir").setValue(new Date(result.tglLahir));
            
            this.messageService.success(
              "Sukses",
              "Nama Lengkap : " + subStr + " ********"
            );
            /*this.formGroup
              .get("namaPasienDukcapil")
              .setValue(subStr + " ********");*/
            
            this.formGroup
              .get("namaPasienDukcapil")
              .setValue(namaPasien);

            for (let i = 0; i < this.listJK.length; i++) {
              const element = this.listJK[i];
              if (
                element.jeniskelamin
                  .toLowerCase()
                  .indexOf(result.JENIS_KLMIN.toLowerCase()) > -1
              ) {
                this.formGroup.get("jenisKelamin").setValue(element);
                break;
              }
            }
            //this.formGroup.get("tglLahir").setValue(new Date(result.TGL_LHR));
            //this.formGroup.get("namaPasien").setValue(result.NAMA_LGKP);
            this.formGroup.get("namaPasien").setValue(result.nama);
          } else {
            this.messageService.error("Info", e.content.RESPON);
          }
        },
        function (err) {
          this.isDukcapil = false;
        }
      );
  }
  getNikTelemedicine() {
    let nik = this.formGroupTelemedicine.get("nik").value;
    if (nik != null && nik.length > 10) this.isDukcapil = false;

    this.httpService
      .get("medifirst2000/bridging/dukcapil/get-nik-indo/" + nik)
      .subscribe(
        (e) => {
          // this.httpService.get('medifirst2000/bridging/dukcapil/get-nik/' + nik).subscribe(e => {
          if (e.content == undefined) {
            return;
          }
          if (e.messages) {
            this.messageService.error("Error", e.messages);
            return;
          }
          if (e.content[0] != "") {
            let result = e.content[0];
            let namaPasien = result.NAMA_LGKP;
            let subStr = namaPasien.substring(0, 5);
            this.isDukcapil = true;
            // this.formHistory.get('namaPasien').setValue(subStr + ' ********')
            this.messageService.success(
              "Sukses",
              "Nama Lengkap : " + subStr + " ********"
            );
            this.formGroupTelemedicine
              .get("namaPasien")
              .setValue(result.NAMA_LGKP);
            this.formGroupTelemedicine
              .get("namaPasienDukcapil")
              .setValue(subStr + " ********");

            for (let i = 0; i < this.listJK.length; i++) {
              const element = this.listJK[i];
              if (
                element.jeniskelamin
                  .toLowerCase()
                  .indexOf(result.JENIS_KLMIN.toLowerCase()) > -1
              ) {
                this.formGroupTelemedicine
                  .get("jenisKelamin")
                  .setValue(element);
                break;
              }
            }
            this.formGroupTelemedicine
              .get("tglLahir")
              .setValue(new Date(result.TGL_LHR));
            this.formGroupTelemedicine
              .get("namaPasien")
              .setValue(result.NAMA_LGKP);
          } else {
            this.messageService.error("Info", e.content.RESPON);
          }
        },
        function (err) {
          this.isDukcapil = false;
        }
      );
  }
  onCariNoRujukanRS(event) {
    this.httpService
      .get(
        "medifirst2000/bridging/bpjs2/get-rujukan-rs-nokartu-multi?nokartu=" +
          event
      )
      .subscribe(
        (e) => {
          if (e.metaData.code == 201) {
            if (this.listRujukan.length == 0) {
              this.messageService.error(
                "Peringatan",
                "Rujukan Tidak Ada atau Masa Berlaku habis,"
              );
              this.loading = false;
              return;
            }
          } else {
            e.response.rujukan.forEach((element) => {
              // debugger
              this.listRujukan.push(element);
              // y.push(element)
            });
            this.listRujukan = this.listRujukan.sort((a, b) =>
              a.tglKunjungan < b.tglKunjungan ? 1 : -1
            );
          }
        },
        (error) => {
          this.messageService.error(
            "Peringatan",
            "Rujukan Tidak Ada atau Masa Berlaku habis,"
          );
        }
      );
  }
  onCariNoRujukanPcare(event) {
    this.httpService
      .get(
        "medifirst2000/bridging/bpjs2/get-rujukan-pcare-nokartu-multi?nokartu=" +
          event
      )
      .subscribe(
        (e) => {
          if (e.metaData.code == 201) {
            if (this.listRujukan.length == 0) {
              this.messageService.error(
                "Peringatan",
                "Rujukan Tidak Ada atau Masa Berlaku habis,"
              );
              this.loading = false;
              return;
            }
          } else {
            e.response.rujukan.forEach((element) => {
              // debugger
              
              let tglRes = new Date(element.tglKunjungan);
              let habisrujukan = new Date(tglRes.setDate(tglRes.getDate() + 90));
              let tglreservasi = new Date(
                moment(this.formGroup.get("tglReservasi").value).format("YYYY-MM-DD")
              );
              let total = (  habisrujukan.getTime() - tglreservasi.getTime()) / (24 * 60 * 60 * 1000);
              if(total > 0){
                  this.listRujukan.push(element);
              }
              // y.push(element)
            });
            this.listRujukan = this.listRujukan.sort((a, b) =>
              a.tglKunjungan < b.tglKunjungan ? 1 : -1
            );
          }
        },
        (error) => {
          this.messageService.error(
            "Peringatan",
            "Rujukan Tidak Ada atau Masa Berlaku habis,"
          );
        }
      );
  }
  choosePilihRujukan(event) {
      debugger;
    
    let tglRes = new Date(event.tglKunjungan);
    let habisrujukan = new Date(tglRes.setDate(tglRes.getDate() + 90));
    let tglreservasi = new Date(moment(this.formGroup.get('tglReservasi').value).format('YYYY-MM-DD'))
    let total = (habisrujukan.getTime() - tglreservasi.getTime()) / (24 * 60 * 60 * 1000);
    if(total < 2){
       this.messageService.warn("Peringatan", "Rujukan Anda Habis Pada tanggal"+ tglRes + "Silahkan Mendatangai Faskes 1 untuk meminta rujukan baru");
       return

    }
    this.showpolikinik =true
    this.showdokter =true
    this.formGroup.get("noRujukan").setValue(event.noKunjungan);
    this.formGroup.get("tglRujukan").setValue(event.tglKunjungan);
    this.ListisBPJSnorujukan = false;
    this.listRuangan.forEach((element) => {
      // debugger
      if (element.kdinternal == event.poliRujukan.kode) {
        this.formGroup.get("poliKlinik").setValue(element);
        this.statuslist = true;
        this.getSlotting2(this.formGroup.get("poliKlinik"));
        this.getSlottingPoli(this.formGroup.get("poliKlinik"));
      }
    });
  }
  
  cekruangan(event) {
    let poliKlinik = this.formGroup.get("poliKlinik").value;
    if (!poliKlinik) {
      this.messageService.warn("Peringatan", "Ruangan  harus di isi !");
      this.statuslist = true;
      return;
    }
     var tglReservasi = this.formGroup.get("tglReservasi").value;
    tglReservasi = moment(
      new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      )
    ).format("YYYY-MM-DD");
    if(event!=undefined){
      this.httpService
        .get(
          "medifirst2000/reservasionline/get-pasienperdokter?id=" +
            event.value.id +
            "&idruangan=" +
            this.formGroup.get("poliKlinik").value.id +
            "&tanggal=" +
            moment(tglReservasi).format("YYYY-MM-DD") +
            "&hari=" +
            moment(tglReservasi).format("d")
        )
        .subscribe(
          (e) => {
            debugger;
            let kuota = 0 
            e.kuota.forEach((element) => {
              kuota = kuota + parseFloat(element.quota);
            });
            if(kuota <= e.data){
               this.messageService.warn(
                "Error",
                "Kuota Dokter hanya sampai " +
                  kuota +
                  " Pasien Saat ini sudah " +
                  e.data
              );
              this.formGroup.get("dokter").setValue("");
              return;
            }
            // if (event.value.kapasitaspasien <= e.data) {
            //   this.messageService.warn(
            //     "Error",
            //     "Kapasitas Dokter di Hafiz hanya sampai " +
            //       event.value.kapasitaspasien +
            //       " Pasien Saat ini sudah " +
            //       e.data
            //   );
            //   this.formGroup.get("dokter").setValue("");
            //   return;
            // }
          },
          (error) => {
            this.listnamaPenjamin = [];
          }
        );
    }
   

    // this.statuslist = false;
  }
  changeAsalRujukan(event) {
    this.formGroup.get("namaPenjamin").setValue("");
     this.formGroup.get('poliKlinik').setValue('')
    this.formGroup.get('tipePembayaran').setValue('')  
    this.formGroup.get("dokter").setValue('')
    this.isBPJS = false 
    this.showtipepembayaran = true
    this.showpolikinik = false
    this.showdokter = false
    this.httpService
      .get(
        "medifirst2000/registrasi/get-penjaminbykelompokpasien?kdKelompokPasien=" +
          event.value.id
      )
      .subscribe(
        (response) => {
          this.listnamaPenjamin = response.rekanan;
        },
        (error) => {
          this.listnamaPenjamin = [];
        }
      );
    this.ListisBPJSnorujukan = false;
    this.statuslist = false;
    
    this.formGroup.get("poliKlinik").setValue(null);
    if (event.value.id == 5) {
      this.statuslist = false;
    }else{
      this.statuslist = true;
    }
    
    if (event.value.id == 2 || event.value.id == 1) {
      if (!this.isBaru && this.noBpjs) {
        this.statuslist = true;
        this.formGroup.get("noKartuPeserta").setValue(this.noBpjs);
        this.formGroup.get("noRujukan").setValue("-");
        this.ListisBPJSnorujukan = true;
        this.listRujukan = [];
        if (this.statusBridging == "1") {
          if (event.value.id  == 7) {
            this.onCariPeserta(this.formGroup.get("noKartuPeserta").value, 1);
            this.NotisBPJS = true;
            this.statuslist = false;
          }else if (event.value.id  == 1 && this.formGroup.get("tipePembayaran").value.id == 2) {
              this.NotisBPJS = false;
              
              this.onCariNoRujukanPcare(this.noBpjs);
             
          }else if (event.value.id  == 2 && this.formGroup.get("tipePembayaran").value.id == 2) {
             this.onCariNoRujukanRS(this.noBpjs);
          } 
        }
      }
      this.isBPJS = true;
    } else {
      if (!this.isBaru) {
        this.formGroup.get("noKartuPeserta").reset();
        this.formGroup.get("noRujukan").reset();
      }
      this.formGroup.get('tipePembayaran').setValue('')
      this.isBPJS = false;
      this.NotisBPJS = true;
    }

    this.showtipepembayaran = true
  }

  changeTipeBayar(event) {
    this.formGroup.get("namaPenjamin").setValue("");
    this.httpService
      .get(
        "medifirst2000/registrasi/get-penjaminbykelompokpasien?kdKelompokPasien=" +
          event.value.id
      )
      .subscribe(
        (response) => { 
          this.listnamaPenjamin = response.rekanan;
        },
        (error) => {
          this.listnamaPenjamin = [];
        }
      );
    this.ListisBPJSnorujukan = false;
    this.statuslist = false;
    
    this.formGroup.get("poliKlinik").setValue(null);

    if (event.value.id == 2) {
      if (!this.isBaru && this.noBpjs) {
        this.statuslist = true;
        this.formGroup.get("noKartuPeserta").setValue(this.noBpjs);
        this.formGroup.get("noRujukan").setValue("-");
        this.ListisBPJSnorujukan = true;
        this.listRujukan = [];
        if (this.statusBridging == "1") {
          this.showpolikinik = false
          this.showdokter = false
          if (this.formGroup.get("asalRujukan").value.id == 7) {
            this.onCariPeserta(this.formGroup.get("noKartuPeserta").value, 1);
            this.NotisBPJS = true;
            this.statuslist = false;
            this.showpolikinik = true
            this.showdokter = true
          }else if (this.formGroup.get("asalRujukan").value.id == 1) {
              this.NotisBPJS = false;
              
              this.onCariNoRujukanPcare(this.noBpjs);
             
          }else if (this.formGroup.get("asalRujukan").value.id == 2) {
             this.onCariNoRujukanRS(this.noBpjs);
          }else {
            this.NotisBPJS = false; 
              this.messageService.error(
                "Peringatan",
                "Jika Anda Menggunakan BPJS Silahkan Pilih Asal Rujukan Puskesmas/Rumah Sakit "
              ); 
          }
        }
      } else if (this.isBaru) { //ash 2023-16-09 if pasien baru dan bpsj
        //--------------------------------------------------------------
        let nopesertabpjs = this.formGroup.get("noKartuPeserta").value;
        if(nopesertabpjs) {

          this.formGroup.get("noRujukan").setValue("-");
          this.ListisBPJSnorujukan = true;
          this.listRujukan = [];
          if (this.statusBridging == "1") {
            this.showpolikinik = false
            this.showdokter = false
            if (this.formGroup.get("asalRujukan").value.id == 7) {
              this.onCariPeserta(this.formGroup.get("noKartuPeserta").value, 1);
              this.NotisBPJS = true;
              this.statuslist = false;
              this.showpolikinik = true
              this.showdokter = true
            }else if (this.formGroup.get("asalRujukan").value.id == 1) {
                this.NotisBPJS = false;
                
                this.onCariNoRujukanPcare(nopesertabpjs);
               
            }else if (this.formGroup.get("asalRujukan").value.id == 2) {
               this.onCariNoRujukanRS(nopesertabpjs);
            }else {
              this.NotisBPJS = false; 
                this.messageService.error(
                  "Peringatan",
                  "Jika Anda Menggunakan BPJS Silahkan Pilih Asal Rujukan Puskesmas/Rumah Sakit "
                ); 
            }
          }
        }
        //--------------------------------------------------------------
      }



      this.isBPJS = true;
      
    
    } else {     
      this.showpolikinik = true      
      this.statuslist = false;
      if (!this.isBaru) {
        this.formGroup.get("noKartuPeserta").reset();
        this.formGroup.get("noRujukan").reset();
      }
      this.isBPJS = false;
      this.NotisBPJS = true;
    }
    
  }

  onMenuButtonClick(event) {
    this.activeItem = {
      icon: "fa fa-fw fa-history",
      label: "History",
    };
  }
  selectedJenis2(select) {
    if (select.id == 1) {
      this.isBaru = true
      this.isLama = false
      this.isLamaNotLengkap = false
      this.formGroup.get('namaPasien').setValue("")
      this.formGroup.get('noCm').setValue("")

//ash
      this.formGroup.get('tglLahir').setValue('')
      this.formGroup.get('noTelpon').setValue('')
      this.formGroup.get('jenisKelamin').setValue('')
      this.formGroup.get('tglLahirLama').setValue('')
      this.formGroup.get('nik').setValue('')
      this.formGroup.get('tempatLahir').setValue('')
      this.formGroup.get('agama').setValue('')
      this.formGroup.get('kebangsaan').setValue('')
      this.formGroup.get('negara').setValue('')
      this.formGroup.get('statusPerkawinan').setValue('')
      this.formGroup.get('pendidikan').setValue('')
      this.formGroup.get('pekerjaan').setValue('')
      this.formGroup.get('suku').setValue('')
      this.formGroup.get('kodePos').setValue('')
      this.formGroup.get('kelurahan').setValue('')
      this.formGroup.get('alamat').setValue('')
      this.formGroup.get('noTelpon').setValue('')
      this.formGroup.get('isNotLengkap').setValue('')

    } else if (select.id == 2) {
      this.isBaru = false
      this.isLama = true
      this.isLamaNotLengkap = false
      this.formGroup.get('namaPasien').setValue("")
      this.formGroup.get('noCm').setValue("")
    } else {
      this.isBaru = false
      this.isLama = false
      this.isLamaNotLengkap = false
    }
    this.selection.selectedJenis2 = select;
  }

  selectedAgree() {
    this.isShow = true;
    this.isHide = false;
  }
  closeItem(event, index) {
    this.items2 = this.items2.filter((item, i) => i !== index);
    event.preventDefault();
  }
  activateMenu() {
    this.activeItem = this.menu["activeItem"];
    if (this.activeItem.label == "Reservasi") {
      this.activeIndex = 0;
    }
    // if (this.activeItem.label == "Bank Account") {
    //   this.loadNoRek()
    // }
  }
  loadNoRek() {
    this.dataSourceBank = [];

    this.httpService
      .get("medifirst2000/reservasionline/get-bank-account")
      .subscribe(
        (response) => {
          this.dataSourceBank = response.data;
        },
        (error) => {
          this.dataSourceBank = [];
        }
      );
  }
  clearHistory() {
    this.formHistory.get("noCm").reset();
    this.formHistory.get("tglLahir").reset();
  }
  clearSlot() {
    this.formGroup.reset();
    this.dataSlot = [];
  }
  
  //ash 2023-09-17
  editHistory(selected) {
    let data = {
      noreservasi: selected.noreservasi,
      noregistrasi: selected.noregistrasi,
      alasan : "Pasien membatalkan reservasi online"
    };
    const now = new Date();
    const reservasi = new Date(selected.tanggalreservasi);
    reservasi.setDate(reservasi.getDate() + 1);
  
    // 👇️ Tomorrow's date
    console.log(reservasi);
    
    const checkNow= Date.parse(now.toDateString());
    const checReservasi= Date.parse(reservasi.toDateString());

    //if (checkNow < checReservasi) {
      
      this.selected2 = selected.objectruanganfk;

      this.isBuktiReservasiUmum = false;
      this.isBuktiReservasiAsuransi = false;
      this.isBuktiReservasi = false;
      this.isBuktiReservasiEdit = true;

    
      let tglReservasiEdit = new Date(moment(selected.tanggalreservasi).format('YYYY-MM-DD'))
      this.formGroup.get("tglReservasi").setValue(tglReservasiEdit);
     
      const toSelectDok = this.listDokter.find(c => c.id == selected.objectpegawaifk); //untuk menampilkan edit pada combobox
      this.formGroup.get("dokter").setValue(toSelectDok);
      
      const toSelect = this.listRuangan.find(c => c.id == selected.objectruanganfk); //untuk menampilkan edit pada combobox
      this.formGroup.get("poliKlinik").setValue(toSelect);
      
      
      const toSelectRujuk = this.listAsalRujukan.find(c => c.id == selected.idasalrujukan); //untuk menampilkan edit pada combobox
      this.formGroup.get("asalRujukan").setValue(toSelectRujuk);
  
      //this.formGroup.get('tipePembayaran').setValue('')
      //this.formGroup.get('namaPenjamin').setValue('')
      //this.formGroup.get('jamReservasi').setValue('')
      //this.formGroup.get('asalRujukan').setValue('')
      //this.formGroup.get('noKartuPeserta').setValue('')
    
      //--hide----------------------------
      this.formGroup.get("namaPasien").setValue(tglReservasiEdit);

      if (selected.kelompokpasien == 'BPJS') this.isBpjsDat = false;
      else this.isBpjsDat = true; 
      

      /*this.confirmationService.confirm({
        message: "Anda Yakin akan mengubah Reservasi?",
        accept: () => {
          this.httpService
            .post("medifirst2000/reservasionline/batal-ro", data)
            .subscribe(
              (res) => {
                this.findHistory();
              },
              (error) => {}
            );
        },
      });*/
      
    /*} else {
      console.log('Edit no');
      this.messageService.error(
        "Maaf Reservasi tidak bisa diubah",
        "Perubahan Reservasi maksimal di hari H"
      );
      return true;
    }*/
  }

  batalHistory(selected) {

    let data = {
      noreservasi: selected.noreservasi,
      noregistrasi: selected.noregistrasi,
      alasan : "Pasien membatalkan reservasi online"
    };
    const now = new Date();
    const reservasi = new Date(selected.tanggalreservasi);
    reservasi.setDate(reservasi.getDate() + 1);
  
    // 👇️ Tomorrow's date
    console.log(reservasi);
    
    const checkNow= Date.parse(now.toDateString());
    const checReservasi= Date.parse(reservasi.toDateString());

    if (checkNow < checReservasi) {
      console.log('Batal ok');
      
      this.confirmationService.confirm({
        message: "Anda Yakin akan membatalkan Reservasi?",
        accept: () => {
          this.httpService
            .post("medifirst2000/reservasionline/batal-ro", data)
            .subscribe(
              (res) => {
                this.findHistory();
              },
              (error) => {}
            );
        },
      });
      return true;
    } else {
      console.log('Batal no');
      this.messageService.error(
        "Maaf Reservasi tidak bisa dibatalkan",
        "Pembatalan Reservasi maksimal di hari H"
      );
      return true;
    }
  
    

  }


  hapusHistory(selected) {
    let data = {
      norec: selected.norec,
    };
    this.confirmationService.confirm({
      message: "Yakin mau menghapus data?",
      accept: () => {
        this.httpService
          .post("medifirst2000/reservasionline/delete", data)
          .subscribe(
            (res) => {
              this.findHistory();
            },
            (error) => {}
          );
      },
    });
  }
  cetakHistory(selected) {
    debugger
    this.namaPasienCetak = selected.namapasien;
    this.noantrian = selected.namahafis + "-" + selected.noantrian;
    this.kodeReservasi = selected.noreservasi;
    this.tglReservasi = selected.tanggalreservasi;
    this.poliTujuan = selected.namaruangan;
    this.dokter = selected.dokter;
    this.statusReservasi = selected.status;
    this.keteranganReservasi = selected.keterangan;
    if (this.isTelemedicine) {
      this.notelp = selected.notelemedicine;
    }
    this.isBuktiReservasiUmum = false;
    this.isBuktiReservasiAsuransi = false;
    this.isBuktiReservasi = false;
    this.isBuktiReservasiEdit = false;

    if (selected.objectkelompokpasienfk == 1) {
      this.isBuktiReservasiUmum = true;
    } else if (selected.objectkelompokpasienfk != 2) {
      this.isBuktiReservasiAsuransi = true;
    }

    this.barCode = this.linkBarcode + selected.noreservasi + this.linkBarcode2;
    QRCode.toDataURL(selected.noreservasi)
      .then((url) => {
        this.qrCode = url;
        console.log(url);
      })
      .catch((err) => {
        console.error(err);
      });
    this.isBuktiReservasi = true;
    // this.displayDialog = true
  }



  findHistory() {
    this.loadingHistory = true;
    let noCM = this.formHistory.get("noCm").value;
    if (noCM == null) {
      this.messageService.warn("Peringatan", "Nama atau No CM harus di isi !");
      return;
    }

    let tglLahirLama = moment(this.formHistory.get("tglLahir").value).format(
      "DD-MM-YYYY"
    );
    if (tglLahirLama == null) {
      this.messageService.warn("Peringatan", "Tgl Lahir harus di isi !");
      return;
    }
    this.httpService
      .get(
        "medifirst2000/reservasionline/get-history?nocmNama=" +
          noCM +
          "&tgllahir=" +
          tglLahirLama
      )
      .subscribe(
        (res) => {
          this.loadingHistory = false;
          if (res.data.length > 0) {
            this.isCollalsedPasien = false;
            // for (let i = 0; i < res.data.length; i++) {
            //   const element = res.data[i];

            //   let tglReserDay = new Date(element.tanggalreservasi)
            //   let _hari = tglReserDay.getDay();
            //   let hariFix = this.listHari[_hari]
            //   element.tanggalreservasi = hariFix + ', ' + moment(new Date(element.tanggalreservasi)).format('DD-MMM-YYYY HH:mm') //HH:mm
            // }
            let namaPasien = res.data[0].namapasien;
            let subStr = namaPasien.substring(0, 5);
            let subStr1 = '';
            let subStr2 = '';
            let subStr3 = '';
            let subStr4 = '';


            if(res.data[0].noidentitas == null  ) {
              subStr1 = '';
            } else {
              subStr1 = res.data[0].noidentitas.substring(0, 5);
            }
            if(res.data[0].nobpjs == null  ) {
              subStr2 = '';
            } else {
              subStr2 = res.data[0].nobpjs.substring(0, 5);
            }


            if(res.data[0].nohp == null  ) {
              subStr4 = '';
              if(res.data[0].notelepon == null  ) {
                subStr4 = '';
              } else {
                subStr4 = res.data[0].notelepon.substring(0, 5);
              }
            } else {
              subStr4 = res.data[0].nohp.substring(0, 5);
            }

            if (res.data[0].istelemedicine) {
              this.isTelemedicine = true;
            } else {
              this.isTelemedicine = false;
            }

            this.formHistory.get("namaPasien").setValue(subStr + "********");
            // this.formHistory.get('namaPasien').setValue(res.data[0].namapasien)
            this.formHistory
              .get("jenisKelamin")
              .setValue(res.data[0].jeniskelamin);
            let notelp = "";
            if (res.data[0].nohp == null) res.data[0].nohp = "";
            if (res.data[0].notelepon == null) res.data[0].notelepon = "";
            //notelp = subStr3 + "********" + " - " + subStr4 + "********";
            notelp = subStr4 + "********";

            this.formHistory.get("nik").setValue(subStr1 + "********");
            this.formHistory
              .get("tempatLahir")
              .setValue(res.data[0].tempatlahir);
            this.formHistory.get("noTelpon").setValue(notelp);
            // this.formHistory.get('noBPJS').setValue(res.data[0].nobpjs != null ? res.data[0].nobpjs : '-')
            this.formHistory
              .get("noAsuransi")
              .setValue(
                res.data[0].nobpjs != null
                  ? subStr2 + "********"
                  : "-" + " / " + res.data[0].noasuransilain != null
                  ? res.data[0].noasuransilain
                  : "-"
              );
            this.formHistory.get("alamat").setValue(res.data[0].alamatlengkap);
            this.formHistory.get("pekerjaan").setValue(res.data[0].pekerjaan);
            this.formHistory.get("pendidikan").setValue(res.data[0].pendidikan);

            this.dataSource = res.data;
          } else {
            this.dataSource = [];
            this.isCollalsedPasien = true;
            this.messageService.info("Info", "Data tidak ada");
          }
        },
        (error) => {
          this.isCollalsedPasien = true;
          this.dataSource = [];
          this.loadingHistory = false;
          this.messageService.info("Info", "Data tidak ada");
        }
      );
  }

  setSourceStepMenu() {
    
    this.stepsMenuItems = [
      {
        label: "Jenis Pasien",
        command: (event: any) => {
          this.activeIndex = 0;
        },
      },
      {
        label: "Tgl Reservasi & Poli",
        command: (event: any) => {
          this.activeIndex = 1;
        },
      },
      {
        label: "Resume",
        command: (event: any) => {
          this.activeIndex = 2;
        },
      },
      // {
      //   label: '',
      //   command: (event: any) => {
      //     this.activeIndex = 3;

      //   }
      // },
    ];
  }
  setSourceStepMenuTelemedicine() {
    this.stepsMenuItemsTelemedicine = [
      {
        label: "Syarat & Ketentuan",
        command: (event: any) => {
          this.activeIndex = 0;
        },
      },
      {
        label: "Tgl Reservasi & Poli",
        command: (event: any) => {
          this.activeIndex = 1;
        },
      },
      {
        label: "Resume",
        command: (event: any) => {
          this.activeIndex = 2;
        },
      },
      // {
      //   label: '',
      //   command: (event: any) => {
      //     this.activeIndex = 3;

      //   }
      // },
    ];
  }
  selectedJenis(e, color) {
    if (e == 1) {
      this.isBaru = true
      this.isLama = false
      this.isLamaNotLengkap = false
    } else if (e == 2) {
      this.isBaru = false
      this.isLama = true
      this.isLamaNotLengkap = false
    } else {
      this.isBaru = false
      this.isLama = false
      this.isLamaNotLengkap = false
    }
  }

  nextWizardTelemedicine(valueIndex) {
    let noCM = this.formGroupTelemedicine.get("noCm").value;
    let jenisKelamin = this.formGroupTelemedicine.get("jenisKelamin").value;
    let tglLahir = this.formGroupTelemedicine.get("tglLahir").value;
    let noTelpon = this.formGroupTelemedicine.get("noTelpon").value;
    let domisili = this.formGroupTelemedicine.get("domisili").value;
    let namaPasien = this.formGroupTelemedicine.get("namaPasien").value;
    let tglLahirLama = this.formGroupTelemedicine.get("tglLahirLama").value;
    let nik = this.formGroupTelemedicine.get("nik").value;

    if (valueIndex == 1) {
      let minDate = new Date();
      if (minDate.getHours() >= 14) {
        this.minDateTelemedicine = new Date(
          new Date().setDate(new Date().getDate() + 2)
        );
      }

      if (this.isShow) {
        if (!noCM) {
          this.messageService.warn("Peringatan", "No RM harus di isi !");
          return;
        }
        // if (!tglLahirLama) {
        //   this.messageService.warn('Peringatan', 'Tgl Lahir harus di isi !')
        //   return
        // }
        // var tgllahir = moment(tglLahirLama).format('DD-MM-YYYY')
        if (tglLahirLama == "") {
          tglLahirLama = null;
        }
        this.httpService
          .get(
            "medifirst2000/reservasionline/get-pasien/" +
              noCM +
              "/" +
              tglLahirLama
          )
          .subscribe(
            (res) => {
              if (res.data.length > 0) {
                if (noCM.length > 6) {
                  this.formGroupTelemedicine
                    .get("noCm")
                    .setValue(res.data[0].nocm);
                }

                this.formGroupTelemedicine
                  .get("tipePembayaranTelemedicine")
                  .setValue(this.listTipePembayaranTelemedicine[0]);

                this.formGroupTelemedicine
                  .get("nik")
                  .setValue(res.data[0].noidentitas);
                this.formGroupTelemedicine
                  .get("noTelpon")
                  .setValue(res.data[0].nohp);
                this.formGroupTelemedicine
                  .get("domisili")
                  .setValue(res.data[0].alamatlengkap);
                this.formGroupTelemedicine
                  .get("namaPasien")
                  .setValue(res.data[0].namapasien);
                let tglLhia = new Date(res.data[0].tgllahir);
                console.log(tglLhia);
                this.formGroupTelemedicine
                  .get("tglLahirLama")
                  .setValue(moment(tglLhia).format("YYYY-MM-DD"));
                this.formGroupTelemedicine
                  .get("jenisKelamin")
                  .setValue({
                    id: res.data[0].objectjeniskelaminfk,
                    jeniskelamin: res.data[0].jeniskelamin,
                  });
                let namaPasien = res.data[0].namapasien;
                let subStr = namaPasien.substring(0, 5);
                this.messageService.success(
                  "Sukses",
                  "Nama Pasien " + subStr + "********"
                );
                this.noBpjs = res.data[0].nobpjs;
                this.activeIndex = valueIndex;
              } else this.messageService.error("Info", "Data tidak ditemukan");
            },
            (error) => {
              this.messageService.error("Info", "Data tidak ditemukan");
            }
          );
      }
    }
    if (valueIndex == 2) {
      // this.getMaxJamReservasi()
      let tglReservasi = this.formGroupTelemedicine.get("tglReservasi").value;
      if (!tglReservasi) {
        this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
        return;
      }
      tglReservasi = new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      );
      let poliKlinik = this.formGroupTelemedicine.get("poliKlinik").value;
      // let dokter = this.formGroupTelemedicine.get('dokter').value
      let tipePembayaranTelemedicine = this.formGroupTelemedicine.get(
        "tipePembayaranTelemedicine"
      ).value;
      let jamReservasi = "00:00";
      let noRujukan = this.formGroupTelemedicine.get("noRujukan").value;
      let noKartuPeserta =
        this.formGroupTelemedicine.get("noKartuPeserta").value;
      let noTelpon = this.formGroupTelemedicine.get("noTelpon").value;
      let domisili = this.formGroupTelemedicine.get("domisili").value;

      if (!domisili) {
        this.messageService.warn(
          "Peringatan",
          "Alamat Domisili harus di isi !"
        );
        return;
      }
      if (!noTelpon) {
        this.messageService.warn("Peringatan", "No Telepon harus di isi !");
        return;
      }
      if (!poliKlinik) {
        this.messageService.warn("Peringatan", "Poliklinik belum di pilih !");
        return;
      }
      // if (!jamReservasi) {
      //   this.messageService.warn('Peringatan', 'Jam Reservasi belum di pilih !')
      //   return
      // }
      // if (!dokter) {
      //   this.messageService.warn('Peringatan', 'Dokter belum di pilih !')
      //   return
      // }
      if (!tipePembayaranTelemedicine) {
        this.messageService.warn(
          "Peringatan",
          "Tipe Pembayaran belum di pilih !"
        );
        return;
      }

      let tgl = "";
      tgl = tglLahirLama;
      this.formGroupTelemedicine.get("isBaru").setValue(false);
      this.formGroupTelemedicine.get("isTelemedicine").setValue(true);

      //if (noCM != null && noCM.length > 6)
      this.formGroupTelemedicine.get("resumeNIK").setValue(nik);
      //else
      this.formGroupTelemedicine.get("resumeNoRM").setValue(noCM);

      let namaPasiensss = namaPasien;
      let subStr = namaPasiensss.substring(0, 5);
      this.formGroupTelemedicine
        .get("resumeNamaDukcapil")
        .setValue(subStr + " *******");
      this.formGroupTelemedicine.get("resumeNama").setValue(namaPasien);
      this.formGroupTelemedicine
        .get("resumeTglLahir")
        .setValue(moment(tgl).format("DD-MM-YYYY"));
      this.formGroupTelemedicine
        .get("resumeJK")
        .setValue(jenisKelamin.jeniskelamin);
      this.formGroupTelemedicine.get("resumeNoTelp").setValue(noTelpon);
      this.formGroupTelemedicine.get("resumeDomisili").setValue(domisili);

      this.formGroupTelemedicine
        .get("resumeTglReservasi")
        .setValue(moment(tglReservasi).format("DD-MM-YYYY") + " 00:00");
      this.formGroupTelemedicine
        .get("resumePoli")
        .setValue(poliKlinik.namaruangan);
      // this.formGroupTelemedicine.get('resumeDokter').setValue(dokter.namalengkap)
      this.formGroupTelemedicine.get("resumeDokter").setValue("-");
      this.formGroupTelemedicine
        .get("resumeTipe")
        .setValue(tipePembayaranTelemedicine.kelompokpasien);
      this.formGroupTelemedicine
        .get("resumNoka")
        .setValue(
          this.formGroupTelemedicine.get("noKartuPeserta").value != null
            ? this.formGroupTelemedicine.get("noKartuPeserta").value
            : ""
        );
      this.formGroupTelemedicine
        .get("resumNoRujukan")
        .setValue(
          this.formGroupTelemedicine.get("noRujukan").value != null
            ? this.formGroupTelemedicine.get("noRujukan").value
            : "-"
        );
      let tipePasiens = "";

      tipePasiens = "lama";

      if (
        this.formGroupTelemedicine.get("tipePembayaranTelemedicine").value.id ==
        2
      ) {
        // cek pasien dihari yang sama hanya boleh satu kali daftar
        let tglLahirs = moment(
          new Date(this.formGroupTelemedicine.get("tglLahir").value)
        ).format("YYYY-MM-DD");
        let cektglReservasi = moment(tglReservasi).format("YYYY-MM-DD");
        this.httpService
          .get(
            "medifirst2000/reservasionline/cek-reservasi-satu?tglReservasi=" +
              cektglReservasi +
              "&tipePasien=" +
              tipePasiens +
              "&noCm=" +
              this.formGroupTelemedicine.get("noCm").value +
              "&tglLahir=" +
              tglLahirs +
              "&namaPasien=" +
              this.formGroupTelemedicine.get("namaPasien").value +
              "&ruanganId=" +
              poliKlinik.id
          )
          .subscribe(
            (e) => {
              if (e.data.length > 0) {
                this.messageService.warn(
                  "Perhatian",
                  "Pasien BPJS di Hari yang sama hanya dapat mendaftar Satu Kali"
                );
                return;
              } else {
                this.onCariNoRujukan(
                  this.formGroupTelemedicine.get("noKartuPeserta").value,
                  valueIndex
                );
                // this.activeIndex = valueIndex
              }
            },
            (error) => {
              this.activeIndex = valueIndex;
            }
          );
      } else {
        this.activeIndex = valueIndex;
      }
      //end cek
    }
    if (valueIndex == 3) {
      this.listJam = [];

      this.activeIndex = valueIndex;
    }
  }

 nextWizard(valueIndex) {
    let noCM = this.formGroup.get('noCm').value
    let jenisKelamin = this.formGroup.get('jenisKelamin').value
    let tglLahir = this.formGroup.get('tglLahir').value
    let noTelpon = this.formGroup.get('noTelpon').value 
    let namaPasien = this.formGroup.get('namaPasien').value
    let tglLahirLama = this.formGroup.get('tglLahirLama').value
    let nik = this.formGroup.get('nik').value

    
    
    if(jenisKelamin === 1) jenisKelamin = 'LAKI-LAKI'
    else if (jenisKelamin === 2) jenisKelamin = 'PEREMPUAN'


    let minDates = new Date();
    // if (minDates.getDay() == 0 || minDates.getDay() == 6) {
    //   this.messageService.warn('Peringatan', 'Reservasi Hari Sabtu/Minggu Tidak bisa dilakukan !')
    //   return
    // }
    console.log(minDates);
    if (minDates.getHours() >= 16) {
      this.minDate = new Date(new Date().setDate(new Date().getDate() + 1));
    }

    if (valueIndex == 1) {
      //--baru      
      if (this.isBaru) {
        //--ash

        let tempatLahir = this.formGroup.get('tempatLahir').value
        let agama = this.formGroup.get('agama').value
        let kebangsaan = this.formGroup.get('kebangsaan').value
        let negara = this.formGroup.get('negara').value
        let statusPerkawinan = this.formGroup.get('statusPerkawinan').value
        let pendidikan = this.formGroup.get('pendidikan').value
        let pekerjaan = this.formGroup.get('pekerjaan').value
        let suku = this.formGroup.get('suku').value
        let kodePos = this.formGroup.get('kodePos').value
        let kelurahan = this.formGroup.get('kelurahan').value
        let alamat = this.formGroup.get('alamat').value
        let datakelurahan=kelurahan.id
        //-----
        if (!nik) {
          this.messageService.warn("Peringatan", "NIK harus di isi ");
          return;
        } else if (nik.length<16) {
          this.messageService.warn("Peringatan", "Periksa kembali NIK yang Anda masukkan");
          return;
        }

        if (!namaPasien) {
          this.messageService.warn("Peringatan", "Nama Pasien harus di isi ");
          return;
        }
        if (!tglLahir) {
          this.messageService.warn("Peringatan", "Tgl Lahir harus di isi ");
          return;
        }



        //--ash -----------------------------------------------------------
        if (tempatLahir.length===0) {
          this.messageService.warn('Peringatan', 'Tempat Lahir harus diisi')
          return
        }
        if (!jenisKelamin) {
          this.messageService.warn(
            "Peringatan",
            "Jenis Kelamin harus di pilih !"
          );
          return;
        }
        if (agama.length===0) {
          this.messageService.warn('Peringatan', 'Agama harus di pilih ')
          return
        }
        if (kebangsaan.length===0) {
          this.messageService.warn('Peringatan', 'Kebangsaan harus di pilih ')
          return
        }
        if (negara.length===0) {
          this.messageService.warn('Peringatan', 'Negara harus di pilih ')
          return
        }
        if (statusPerkawinan.length===0) {
          this.messageService.warn('Peringatan', 'Status Perkawinan harus di pilih ')
          return
        }
        if (pendidikan.length===0) {
          this.messageService.warn('Peringatan', 'Pendidikan harus di pilih ')
          return
        }
        if (pekerjaan.length===0) {
          this.messageService.warn('Peringatan', 'Pekerjaan harus di pilih ')
          return
        }
        if (suku.length===0) {
          this.messageService.warn('Peringatan', 'Suku harus di pilih ')
          return
        }
        if (!alamat) {
          this.messageService.warn('Peringatan', 'Alamat harus diisi ')
          return
        }
        if (!kodePos) {
          this.messageService.warn('Peringatan', 'Kode Pos harus di pilih ')
          return
        }
        if (datakelurahan.length===0) {
          this.messageService.warn('Peringatan', 'Kelurahan harus di pilih ')
          return
        }
        if (!noTelpon) {
          this.messageService.warn("Peringatan", "No Telepon harus di isi ");
          return;
        }



        if (nik.length > 14) {
          this.httpService
            .get("medifirst2000/reservasionline/cek-pasien-baru-by-nik/" + nik)
            .subscribe((e) => {
              if (e.data.length > 0) {
                this.messageService.info(
                  "Info",
                  "Pasien ini adalah pasien lama dengan No RM " + e.data[0].nocm
                );
                this.messageService.info(
                  "Info",
                  "Mohon daftar dengan Tipe Pasien Lama"
                );
                return;
              } else this.activeIndex = valueIndex;
            });
        } else {
          this.activeIndex = valueIndex;
        }

        this.formGroup.get('tglLahirLama').setValue(tglLahir);


      }


      //--LAMA NOT LENGKAP
      //--ash
      if (this.isLamaNotLengkap) {
        let noCM = this.formGroup.get('noCm').value
        let namaPasien = this.formGroup.get('namaPasien').value
        let tglLahirLama = this.formGroup.get('tglLahirLama').value
        let nik = this.formGroup.get('nik').value
        let jenisKelamin = this.formGroup.get('jenisKelamin').value
        let tempatLahir = this.formGroup.get('tempatLahir').value
        let agama = this.formGroup.get('agama').value
        let kebangsaan = this.formGroup.get('kebangsaan').value
        let negara = this.formGroup.get('negara').value
        let statusPerkawinan = this.formGroup.get('statusPerkawinan').value
        let pendidikan = this.formGroup.get('pendidikan').value
        let pekerjaan = this.formGroup.get('pekerjaan').value
        let suku = this.formGroup.get('suku').value
        let alamat = this.formGroup.get('alamat').value
        let kodePos = this.formGroup.get('kodePos').value
        let kelurahan = this.formGroup.get('kelurahan').value
        let noTelpon = this.formGroup.get('noTelpon').value

        let datakelurahan= kelurahan.id
        debugger
        if (!noCM) {
          this.messageService.warn('Peringatan', 'noCM harus di isi ')
          return
        }  

        if (!nik) {
          this.messageService.warn('Peringatan', 'NIK harus di isi ')
          return
        } else if (nik.length<16) {
          this.messageService.warn("Peringatan", "Periksa kembali NIK yang Anda masukkan");
          return;
        }

        if (!namaPasien) {
          this.messageService.warn('Peringatan', 'Nama Pasien harus di isi ')
          return
        }
        if (!tglLahirLama) {
          this.messageService.warn('Peringatan', 'Tgl Lahir harus di isi ')
          return
        }
        if (jenisKelamin.length===0) {
          this.messageService.warn('Peringatan', 'Jenis Kelamin harus di pilih ')
          return
        }
        if (tempatLahir.length===0) {
          this.messageService.warn('Peringatan', 'Tempat Lahir harus diisi ')
          return
        }
        if (agama.length===0) {
          this.messageService.warn('Peringatan', 'Agama harus di pilih ')
          return
        }
        if (kebangsaan.length===0) {
          this.messageService.warn('Peringatan', 'Kebangsaan harus di pilih ')
          return
        }
        if (negara.length===0) {
          this.messageService.warn('Peringatan', 'Negara harus di pilih ')
          return
        }
        if (statusPerkawinan.length===0) {
          this.messageService.warn('Peringatan', 'Status Perkawinan harus di pilih ')
          return
        }
        if (pendidikan.length===0) {
          this.messageService.warn('Peringatan', 'Pendidikan harus di pilih ')
          return
        }
        if (pekerjaan.length===0) {
          this.messageService.warn('Peringatan', 'Pekerjaan harus di pilih ')
          return
        }
        if (suku.length===0) {
          this.messageService.warn('Peringatan', 'Suku harus di pilih ')
          return
        }
        if (!alamat) {
          this.messageService.warn('Peringatan', 'Alamat harus diisi')
          return
        }
        if (!kodePos) {
          this.messageService.warn('Peringatan', 'Kode Pos harus diisi')
          return
        }
        if (datakelurahan.length===0) {
          this.messageService.warn('Peringatan', 'Kelurahan harus di pilih ')
          return
        }
        if (!noTelpon) {
          this.messageService.warn('Peringatan', 'No Telepon harus di isi ')
          return
        }

        
        this.formGroup.get('isNotLengkap').setValue("1")  
        this.isLamaNotLengkap = false
        this.activeIndex = valueIndex
        let subStr = namaPasien.substring(0, 5)
        this.messageService.success('Sukses', 'Nama Pasien ' + subStr + '********')
      } else {
      //--LAMA      
        if (this.isLama) {
          if (!noCM) {
            this.messageService.warn('Peringatan', 'No RM harus di isi !')
            return
          }
          // if (noCM.length != 9) {
          //   this.messageService.warn('Peringatan', 'No RM harus 9 Digit, Jika No.Rm Pasien 1234. maka jadi 00001234!')
          //   return
          // }
          // if (!tglLahirLama) {
          //   this.messageService.warn('Peringatan', 'Tgl Lahir harus di isi !')
          //   return
          // }
          // var tgllahir = moment(tglLahirLama).format('DD-MM-YYYY')
          this.formGroup.get('noCm').setValue('')
          this.formGroup.get('jenisKelamin').setValue('')
          this.formGroup.get('tglLahir').setValue('')
          this.formGroup.get('noTelpon').setValue('')
          this.formGroup.get('namaPasien').setValue('')
          this.formGroup.get('tglLahirLama').setValue('')
          this.formGroup.get('resumeNIK').setValue('')
          this.formGroup.get('tanggalRujukan').setValue('')
          this.formGroup.get('noRujukan').setValue('')
          this.formGroup.get('tglRujukan').setValue('')
          this.formGroup.get('nokartuBpjs').setValue('')
          this.formGroup.get('noKartuPeserta').setValue('')
          this.formGroup.get('tglReservasi').setValue('')
          this.formGroup.get('poliKlinik').setValue('')
          this.formGroup.get('tipePembayaran').setValue('')
          this.formGroup.get('namaPenjamin').setValue('')
          this.formGroup.get('jamReservasi').setValue('')
          this.formGroup.get('asalRujukan').setValue('')

          //ash
          this.formGroup.get('nik').setValue('')
          this.formGroup.get('tempatLahir').setValue('')
          this.formGroup.get('agama').setValue('')
          this.formGroup.get('kebangsaan').setValue('')
          this.formGroup.get('negara').setValue('')
          this.formGroup.get('statusPerkawinan').setValue('')
          this.formGroup.get('pendidikan').setValue('')
          this.formGroup.get('pekerjaan').setValue('')
          this.formGroup.get('suku').setValue('')
          this.formGroup.get('alamat').setValue('')
          this.formGroup.get('kodePos').setValue('')
          this.formGroup.get('kelurahan').setValue('')
          this.formGroup.get('noTelpon').setValue('')
          this.formGroup.get('isNotLengkap').setValue('')
          // this.formGroup.get("norujukan").enable();
          // this.formGroup.get("nokartupesarta").disable();

          if(tglLahirLama ==''){
            tglLahirLama = null;
          }
          this.httpService.get('medifirst2000/reservasionline/get-pasien/' + noCM + '/' + tglLahirLama).subscribe(res => {
            if (res.data.length > 0) {
              if (res.data[0].blacklist != null) {
                this.messageService.error("Error", res.data[0].blacklist+' Silahkan Datang ke Onsite' );
                return;
              }
              if (noCM.length > 6) {
                this.formGroup.get('noCm').setValue(res.data[0].nocm)
              }

              
                this.formGroup.get('nik').setValue(res.data[0].noidentitas) //ash
                this.formGroup.get('noTelpon').setValue(res.data[0].nohp)
                this.formGroup.get('namaPasien').setValue(res.data[0].namapasien)
                let tglLhia = new Date(res.data[0].tgllahir)
                //console.log(tglLhia)
                this.formGroup.get('tglLahirLama').setValue(moment(tglLhia).format('YYYY-MM-DD'))
                //this.formGroup.get('jenisKelamin').setValue({ id: res.data[0].objectjeniskelaminfk, jeniskelamin: res.data[0].jeniskelamin })
                let namaPasien = res.data[0].namapasien
                let subStr = namaPasien.substring(0, 5)
                this.messageService.success('Sukses', 'Nama Pasien ' + subStr + '********')
                this.noBpjs = res.data[0].nobpjs

                const sample_array: {
                  id: number;
                  datakelurahan: string;
                  }[ ] = [
                  { id: res.data[0].objectdesakelurahanfk, datakelurahan: "test" }
                  ];
                //---ash------------------------------------
                this.formGroup.get('agama').setValue(res.data[0].objectagamafk)
                this.formGroup.get('tempatLahir').setValue(res.data[0].tempatlahir)
                this.formGroup.get('kodePos').setValue(res.data[0].kodepos)
                this.formGroup.get('jenisKelamin').setValue(res.data[0].objectjeniskelaminfk)
                this.formGroup.get('kebangsaan').setValue(res.data[0].objectkebangsaanfk)
                this.formGroup.get('negara').setValue(res.data[0].objectnegarafk)
                this.formGroup.get('statusPerkawinan').setValue(res.data[0].objectstatusperkawinanfk)
                this.formGroup.get('pendidikan').setValue(res.data[0].objectpendidikanfk)
                this.formGroup.get('pekerjaan').setValue(res.data[0].objectpekerjaanfk)
                this.formGroup.get('suku').setValue(res.data[0].sukufk)
                this.formGroup.get('kelurahan').setValue(res.data[0].objectdesakelurahanfk)   
                this.formGroup.get('alamat').setValue(res.data[0].alamatlengkap)             
                  
                
                //ash 2023-09-28
                this.formGroup.get('nokartuBpjs').setValue(res.data[0].nobpjs)   
                this.formGroup.get('noKartuPeserta').setValue(res.data[0].nobpjs)             
                  
                debugger
                //ash
                //&& (res.data[0].kebangsaan!==null) && (res.data[0].negara!==null) && (res.data[0].statusperkawinan!==null) && (res.data[0].pendidikan!==null) && (res.data[0].pekerjaan!==null)  
                if((res.data[0].noidentitas!==null) && (res.data[0].noidentitas.length==16) && (res.data[0].nohp!==null)
                && (res.data[0].namapasien!==null) && (res.data[0].tgllahir!==null)
                && (res.data[0].agama!==null) && (res.data[0].jeniskelamin!==null)
                && (res.data[0].objectdesakelurahanfk!==null) && (res.data[0].alamatlengkap!==null)
                ) {
                  this.activeIndex = valueIndex
                } else {
                  this.isLamaNotLengkap = true
                  this.formGroup.get('isNotLengkap').setValue("1")  
                  this.messageService.error('Info', 'Data Anda belum lengkap!\nSilakan lengkapi data pribadi Anda')
                }
            } else
              this.messageService.error('Info', 'Data tidak ditemukan!')
          },   (error) => {
            // this.messageService.error('Info', error)
          })
        }
      }
    }
    if (valueIndex == 2) {
      // this.getMaxJamReservasi()
      let tglReservasi = this.formGroup.get("tglReservasi").value;
      tglReservasi = new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      );
      let poliKlinik = this.formGroup.get("poliKlinik").value;
      let dokter = this.formGroup.get("dokter").value;
      let tipePembayaran = this.formGroup.get("tipePembayaran").value;
      let namaPenjamin = this.formGroup.get("namaPenjamin").value;
      // let jamReservasi = this.formGroup.get('jamReservasi').value
      let noRujukan = this.formGroup.get('noRujukan').value
      let tglRujukan = this.formGroup.get('tglRujukan').value
      let noKartuPeserta = this.formGroup.get('noKartuPeserta').value
      let asalRujukan = this.formGroup.get('asalRujukan').value
      let nik = this.formGroup.get('nik').value
      


      //ash 2023-09-28
      if(this.formGroup.get("nokartuBpjs").value!=null){
        this.formGroup.get("noKartuPeserta").setValue(this.formGroup.get("nokartuBpjs").value);
      } 

      
      if(!dokter){
        this.messageService.warn('Peringatan', 'Dokter  harus di isi !')
        return
      }
      if (!tglReservasi) {
        this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
        return;
      }
      if (!poliKlinik) {
        this.messageService.warn("Peringatan", "Poliklinik belum di pilih !");
        return;
      }
      // if (!jamReservasi) {
      //   this.messageService.warn('Peringatan', 'Jam Reservasi belum di pilih !')
      //   return
      // }
      // if (!dokter) {
      //   this.messageService.warn('Peringatan', 'Dokter belum di pilih !')
      //   return
      // }
      if (!tipePembayaran) {
        this.messageService.warn(
          "Peringatan",
          "Tipe Pembayaran belum di pilih !"
        );
        return;
      }

      // var someDate = new Date();
      // var duration = tglRujukan; //In Days
      // someDate.setTime(someDate.getDate() +  (duration * 24 * 60 * 60 * 1000));

      if (tipePembayaran) {
        if (tipePembayaran.id == 2) {
          let now = new Date();
          let date = tglRujukan;
          // this.firstDate = moment(tglRujukan).format('YYYY/MM/DD');
          // this.secondDate = moment(now).format('YYYY/MM/DD');
          // this.diffInDays = Math.abs(this.firstDate.diff(this.secondDate));
          // let days = Math.ceil(this.diffInDays / (1000 * 60 * 60 * 24))
          let days = moment(now, "YYYY-MM-DD").diff(
            moment(date, "YYYY-MM-DD"),
            "days"
          );
          if (days > 90) {
            this.messageService.warn(
              "Perhatian",
              "Tanggal Rujukan Sudah Kadaluarsa!"
            );
            return;
          }
        }
      }

      let tanggalRujukan = "";
      //    if(tipePembayaran){
      //     if(tipePembayaran.id == 2){
      //       if(tglRujukan){
      //          this.httpService.get('medifirst2000/reservasionline/cek-tanggal-rujukan?tglRujukan=' + tglRujukan
      //         ).subscribe(data => {
      //           if (data > 90) {
      //             this.tanggalRujukan = data
      //             this.messageService.warn('Perhatian', 'Tanggal Rujukan Sudah Kadaluarsa!')
      //             return
      //           }
      //           if(tipePembayaran){
      //             if(tipePembayaran.id == 2){
      //               if(this.tanggalRujukan){
      //                 tanggalRujukan = this.tanggalRujukan
      //               }
      //             }
      //           }
      //         })
      //       }
      //   }
      // }

      if (!namaPenjamin) {
        this.messageService.warn(
          "Peringatan",
          "Nama Penjamin belum di pilih !"
        );
        return;
      }
      if (tipePembayaran.id == 2) {
        if (!noKartuPeserta) {
          this.messageService.warn(
            "Peringatan",
            "No Kartu Peserta belum di isi !"
          );
          return;
        }
        if (!noRujukan) {
          this.messageService.warn("Peringatan", "No Rujukan belum di isi !");
          return;
        }
      }
      if (!asalRujukan) {
        this.messageService.warn("Peringatan", "Asal Rujukan belum di isi !");
        return;
      }

      let tgl = "";
      if (this.isBaru) {
        tgl = tglLahir;
        this.formGroup.get("isBaru").setValue(true);
      } else {
        tgl = tglLahirLama;
        this.formGroup.get("isBaru").setValue(false);
      }

      //if (noCM != null && noCM.length > 8)
      this.formGroup.get("resumeNIK").setValue(nik);
      //else
        this.formGroup.get('resumeNoRM').setValue(noCM)

      let namaPasiensss = namaPasien
      let subStr = namaPasiensss.substring(0, 5)
      this.formGroup.get('resumeNamaDukcapil').setValue(subStr + ' *******')
      this.formGroup.get('resumeNama').setValue(namaPasien)
      this.formGroup.get('resumeTglLahir').setValue(moment(tgl).format('DD-MM-YYYY'))
      this.formGroup.get('resumeJK').setValue(jenisKelamin)
      this.formGroup.get('resumeNoTelp').setValue(noTelpon)
      this.formGroup.get('resumetglRujukan').setValue(tanggalRujukan)

      // this.formGroup.get('resumeTglReservasi').setValue(moment(tglReservasi).format('DD-MM-YYYY') + ' ' + jamReservasi.jam)
      this.formGroup
        .get("resumeTglReservasi")
        .setValue(moment(tglReservasi).format("DD-MM-YYYY"));
      this.formGroup.get("resumePoli").setValue(poliKlinik.namaruangan);

      this.formGroup.get("resumeDokter").setValue(dokter.namalengkap);
      // this.formGroup.get('resumeDokter').setValue('-')
      this.formGroup.get("resumeTipe").setValue(tipePembayaran.kelompokpasien);
      this.formGroup
        .get("resumNoka")
        .setValue(
          this.formGroup.get("noKartuPeserta").value != null
            ? this.formGroup.get("noKartuPeserta").value
            : ""
        );
      this.formGroup
        .get("resumNoRujukan")
        .setValue(
          this.formGroup.get("noRujukan").value != null
            ? this.formGroup.get("noRujukan").value
            : "-"
        );
      let tipePasiens = "";
      if (this.isBaru) {
        tipePasiens = "baru";
      } else {
        tipePasiens = "lama";
      }

      if (this.formGroup.get("tipePembayaran").value.id == 2) {
        // cek pasien dihari yang sama hanya boleh satu kali daftar
        let tglLahirs = moment(
          new Date(this.formGroup.get("tglLahir").value)
        ).format("YYYY-MM-DD");
        let cektglReservasi = moment(tglReservasi).format("YYYY-MM-DD");
        this.httpService
          .get(
            "medifirst2000/reservasionline/cek-reservasi-satu?tglReservasi=" +
              cektglReservasi +
              "&tipePasien=" +
              tipePasiens +
              "&noCm=" +
              this.formGroup.get("noCm").value +
              "&tglLahir=" +
              tglLahirs +
              "&namaPasien=" +
              this.formGroup.get("namaPasien").value +
              "&ruanganId=" +
              poliKlinik.id
          )
          .subscribe(
            (e) => {
              if (e.data.length > 0) {
                this.messageService.warn(
                  "Perhatian",
                  "Pasien BPJS di Hari yang sama hanya dapat mendaftar Satu Kali"
                );
                return;
              } else {
                if (this.formGroup.get("asalRujukan").value.id == 7) {
                  if (this.statusBridging == "1")
                    this.onCariPeserta(
                      this.formGroup.get("noKartuPeserta").value,
                      valueIndex
                    );
                }
                // else {
                //   this.onCariNoRujukan(this.formGroup.get('noKartuPeserta').value, valueIndex)
                // }
                this.activeIndex = valueIndex;
              }
            },
            (error) => {
              this.activeIndex = valueIndex;
            }
          );
      } else {
        this.activeIndex = valueIndex;
      }
      //end cek
    }
    if (valueIndex == 3) {
      this.listJam = [];

      this.activeIndex = valueIndex;
    }
  }

  onCariPeserta(searchValue: string, valueIndex): void {
    this.httpService
      .get(
        "medifirst2000/bridging/bpjs2/get-no-peserta?nokartu=" +
          searchValue +
          "&tglsep=" +
          moment(new Date()).format("YYYY-MM-DD")
      )
      .subscribe(
        (e) => {
          if (e.response != null) {
            this.formGroup.get("noRujukan").setValue("Pasca Ranap");
            this.formGroup
              .get("resumNoRujukan")
              .setValue(
                this.formGroup.get("noRujukan").value != null
                  ? this.formGroup.get("noRujukan").value
                  : "-"
              );
            this.activeIndex = valueIndex;
          } else {
            // this.statusRujukanBerlaku = false
            this.messageService.error(
              "Peringatan",
              "Peserta tidak di temukan,"
            );
          }
        },
        (error) => {
          // this.statusRujukanBerlaku = false
          this.messageService.error("Peringatan", "Peserta tidak di temukan,");
        }
      );
  }
  onCariNoRujukan(searchValue: string, valueIndex): void {
    // if (searchValue.length <= 14) {
    //   this.cekPesertaByNoKartu(this.formGroup.get('noRujukan').value)
    // } else {
    //   this.cekPesertaByNoRujukan(this.formGroup.get('noRujukan').value)
    // }
    this.httpService
      .get(
        "medifirst2000/bridging/bpjs2/get-rujukan-pcare-nokartu?nokartu=" +
          searchValue
      )
      .subscribe(
        (e) => {
          if (e.response != null) {
            let idRuangan = this.formGroup.get("poliKlinik").value.kdinternal;
            if (idRuangan != e.response.rujukan.poliRujukan.kode) {
              debugger;
              this.messageService.error(
                "Peringatan",
                "Rujukan Anda Ke Harusnya Ke" +
                  e.response.rujukan.poliRujukan.nama +
                  " Silahkan Ganti Poli"
              );
              return;
            }
            this.formGroup
              .get("noRujukan")
              .setValue(e.response.rujukan.noKunjungan);
            this.formGroup
              .get("resumNoRujukan")
              .setValue(
                this.formGroup.get("noRujukan").value != null
                  ? this.formGroup.get("noRujukan").value
                  : "-"
              );
            this.activeIndex = valueIndex;
          } else {
            this.httpService
              .get(
                "medifirst2000/bridging/bpjs2/get-rujukan-rs-nokartu?nokartu=" +
                  searchValue
              )
              .subscribe(
                (e) => {
                  if (e.response != null) {
                    let idRuangan =
                      this.formGroup.get("poliKlinik").value.kdinternal;
                    if (idRuangan != e.response.rujukan.poliRujukan.kode) {
                      debugger;
                      this.messageService.error(
                        "Peringatan",
                        "Rujukan Anda Ke Harusnya Ke" +
                          e.response.rujukan.poliRujukan.nama +
                          " Silahkan Ganti Poli"
                      );
                      return;
                    }
                    this.formGroup
                      .get("noRujukan")
                      .setValue(e.response.rujukan.noKunjungan);
                    this.formGroup
                      .get("resumNoRujukan")
                      .setValue(
                        this.formGroup.get("noRujukan").value != null
                          ? this.formGroup.get("noRujukan").value
                          : "-"
                      );
                    this.activeIndex = valueIndex;
                  } else {
                    // this.statusRujukanBerlaku = false
                    this.activeIndex = valueIndex;
                    this.messageService.error(
                      "Peringatan",
                      "Rujukan Tidak Ada atau Masa Berlaku habis,"
                    );
                  }
                },
                (error) => {
                  // this.statusRujukanBerlaku = false
                  this.activeIndex = valueIndex;
                  this.messageService.error(
                    "Peringatan",
                    "Rujukan Tidak Ada atau Masa Berlaku habis,"
                  );
                }
              );
          }
        },
        (error) => {
          // this.statusRujukanBerlaku = false
          this.activeIndex = valueIndex;
          this.messageService.error(
            "Peringatan",
            "Rujukan Tidak Ada atau Masa Berlaku habis, "
          );
        }
      );
  }
  getSlotting2(event) {
    console.log(event);
    var tglReservasi = this.formGroup.get("tglReservasi").value;
    if (!tglReservasi) {
      this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
      return;
    }
    let idRuangan = event.value.id;
    let idRuanganbpjs = event.value.kdinternal;
    if (idRuangan == undefined) {
      idRuangan =
        this.formGroup.get("poliKlinik").value != null
          ? this.formGroup.get("poliKlinik").value.id
          : undefined;
    }
    if (idRuangan == undefined) {
      return;
    }
    var tglReservasi = this.formGroup.get("tglReservasi").value;
    tglReservasi = moment(
      new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      )
    ).format("YYYY-MM-DD");
    this.formGroup.get("dokter").setValue("");
    this.listDokter = [];

    if(idRuangan == 322) {
      console.log(idRuangan);
      
      // this.httpService
      // .get("medifirst2000/reservasionline/get-jadwal-doktermcu?idRuangan=" + idRuangan)
      // .subscribe((res) => {

      // });
      //-----------------------
      this.httpService
      .get("medifirst2000/reservasionline/get-jadwal-doktermcu?idRuangan=" + idRuangan)
      .subscribe((res) => {
       if (res.length > 0) {
         res.forEach(element=> {
           //this.listDokter.push(element);
                      this.listDokter.push({
                        id: element.id,
                        namalengkap: element.namalengkap,
                        idhafis: element.idhafis,
                        namahafis: element.namahafis,
                        jadwal: "08:00-12:00",
                        kodesubspesialis: "-",
                        kapasitaspasien: 100
                      });
         });
       }
      });
    } else {
      this.httpService
      .get(
        "medifirst2000/reservasionline/get-jadwal-dokterall?idRuangan=" +
          idRuangan +
          "&tglreservasi=" +
          tglReservasi
      )
      .subscribe((res) => {
        if (res.length > 0) {
          let listDokterTemp = [];
          res.forEach((element) => {
            // this.listDokter.push(element)
            listDokterTemp.push(element);
          });
          this.httpService
            .get(
              "medifirst2000/bridging/bpjs2/mjkn/antrian/getjadwal?kodepoli=" +
                idRuanganbpjs +
                "&tanggal=" +
                moment(tglReservasi).format("YYYY-MM-DD")
            )
            .subscribe((data) => {
              if (data.metaData.code == 200) {
                this.listDokter = [];
                this.listDokter.push({
                  label: "--Pilih Dokter --",
                  value: null,
                });
                for (let i = 0; i < data.response.length; i++) {
                  for (let x = 0; x < listDokterTemp.length; x++) {
                    if (
                      data.response[i].kodedokter == listDokterTemp[x].idhafis
                    ) {
                      debugger;
                      this.listDokter.push({
                        id: listDokterTemp[x].id,
                        namalengkap: data.response[i].namadokter,
                        idhafis: data.response[i].kodedokter,
                        namahafis: listDokterTemp[x].namahafis,
                        jadwal: data.response[i].jadwal,
                        kodesubspesialis: data.response[i].kodesubspesialis,
                        kapasitaspasien: data.response[i].kapasitaspasien,
                      });
                    }
                  }
                }
              }
            });
          this.isSloting2 = true;
        } else {
          this.listDokter = [];
          this.isSloting2 = false;
        }
      });
    }

    
  }
  cekPasienMendaftarDihariSama(tglReser, tipePasien) {
    let tglLahir = moment(
      new Date(this.formGroup.get("tglLahir").value)
    ).format("YYYY-MM-DD");
    tglReser = moment(tglReser).format("YYYY-MM-DD");
    this.httpService
      .get(
        "medifirst2000/reservasionline/cek-reservasi-satu?tglReservasi=" +
          tglReser +
          "&tipePasien=" +
          tipePasien +
          "&noCm=" +
          this.formGroup.get("noCm").value +
          "&tglLahir=" +
          tglLahir +
          "&namaPasien=" +
          this.formGroup.get("namaPasien").value
      )
      .subscribe(
        (e) => {},
        (error) => {}
      );
  }

  
  getKelurahan(namakelurahan) {
      this.httpService
      .get(
        "medifirst2000/reservasionline/get-kelurahan?" + namakelurahan 
      )
      .subscribe(
        (e) => {
          e.kelurahan.forEach(element => {
            this.listKelurahan.push(element)
          });
        },
        (error) => {}
      );
  }

  setSourceCombo() {
    this.httpService.get('medifirst2000/reservasionline/get-list-data').subscribe(e => {

      e.jeniskelamin.forEach(element => {
        this.listJK.push(element)
      });

      e.ruanganrajalKiosk.forEach(element => {
        this.listRuangan.push(element)
      });

      e.dokter.forEach(element => {
        this.listDokter.push(element)
      });

      e.kelompokpasien.forEach(element => {
        this.listTipePembayaran.push(element)
      });

      e.kelompokpasientelemedicine.forEach(element => {
        this.listTipePembayaranTelemedicine.push(element)
      });

      e.asalrujukan.forEach(element => {
        this.listAsalRujukan.push(element)
      });

      //-- ash 26-06-2023 ----
      e.agama.forEach(element => {
        this.listAgama.push(element)
      });

      e.kebangsaan.forEach(element => {
        this.listKebangsaan.push(element)
      });

      e.negara.forEach(element => {
        this.listNegara.push(element)
      });
      
      e.statusperkawinan.forEach(element => {
        this.listStatusPerkawinan.push(element)
      });

      e.pendidikan.forEach(element => {
        this.listPendidikan.push(element)
      });
      
      e.pekerjaan.forEach(element => {
        this.listPekerjaan.push(element)
      });
           
      e.suku.forEach(element => {
        this.listSuku.push(element)
      });
      
      e.kelurahan.forEach(element => {
        this.listKelurahan.push(element)
      });
      
      //----------------------


    }, error => {

    })

    // this.filteredOptions = this.formGroup.get('dokter').valueChanges
    // .pipe(
    //   startWith(''),
    //   map(value => this._filter(value))
    // );
  }

  save() {
    if (this.isBaru) {
      this.formGroup
        .get("tglLahir")
        .setValue(
          moment(this.formGroup.get("tglLahir").value).format("YYYY-MM-DD")
        );
    } else {
      this.formGroup
        .get("tglLahir")
        .setValue(
          moment(this.formGroup.get("tglLahirLama").value).format("YYYY-MM-DD")
        );
    }
    let tglReservasi = this.formGroup.get("tglReservasi").value;
    tglReservasi = new Date(
      tglReservasi._i.year,
      tglReservasi._i.month,
      tglReservasi._i.date
    );
    // var tanggal: any = moment(tglReservasi).format('YYYY-MM-DD') + ' ' + this.formGroup.get('jamReservasi').value.jam
    var tanggal: any = moment(tglReservasi).format("YYYY-MM-DD 00:00");
    // console.log(tanggal)
    this.formGroup.get("tglReservasiFix").setValue(tanggal);

    this.formGroup.get("dokter").setValue({
      id: this.formGroup.get("dokter").value.id,
      namalengkap: this.formGroup.get("dokter").value.namalengkap,
      namahafis: this.formGroup.get("dokter").value.namahafis,
      jadwal: this.formGroup.get("dokter").value.jadwal,
    });
    // this.formGroup.get('dokter').setValue(null)

    let tglRujukan = this.formGroup.get("resumetglRujukan").value;

    // if(tglRujukan){
    //     if (tglRujukan > 90) {
    //       this.messageService.warn('Peringatan', 'Tanggal Rujukan Sudah Kadaluarsa!')
    //       return
    //     }
    //   }

    this.confirmationService.confirm({
      message: "Yakin mau menyimpan data?",
      accept: () => {
        this.simpanVisible = false; 
        debugger
        if (this.isBaru) {
          this.httpService
            .post("medifirst2000/reservasionline/save", this.formGroup.value)
            .subscribe(
              (res) => {
                if (this.isBaru) {
                  this.formHistory
                    .get("noCm")
                    .setValue(this.formGroup.get("namaPasien").value);
                } else {
                  this.formHistory
                    .get("noCm")
                    .setValue(this.formGroup.get("noCm").value);
                }
                this.formHistory
                  .get("tglLahir")
                  .setValue(this.formGroup.get("tglLahir").value);
                let tglLahirLama = moment(
                  this.formHistory.get("tglLahir").value
                ).format("DD-MM-YYYY");
                this.httpService
                  .get(
                    "medifirst2000/reservasionline/get-history?nocmNama=" +
                      this.formHistory.get("noCm").value +
                      "&tgllahir=" +
                      tglLahirLama
                  )
                  .subscribe(
                    (res) => {
                      this.loadingHistory = false;
                      if (res.data.length > 0) {
                        this.isCollalsedPasien = false;
                        // for (let i = 0; i < res.data.length; i++) {
                        //   const element = res.data[i];
                        //   let tglReserDay = new Date(element.tanggalreservasi)
                        //   let _hari = tglReserDay.getDay();
                        //   let hariFix = this.listHari[_hari]
                        //   element.tanggalreservasi = hariFix + ', ' + moment(new Date(element.tanggalreservasi)).format('DD-MMM-YYYY HH:mm') //HH:mm
                        //   // element.tanggalreservasi = moment(new Date(element.tanggalreservasi)).format('DD-MMM-YYYY HH:mm')
                        // }
                        let namaPasien = res.data[0].namapasien;
                        let subStr = namaPasien.substring(0, 5);

                        this.formHistory
                          .get("namaPasien")
                          .setValue(subStr + "********");
                        this.formHistory
                          .get("jenisKelamin")
                          .setValue(res.data[0].jeniskelamin);
                        let notelp = "";
                        if (res.data[0].notelepon == null)
                          res.data[0].notelepon = "";
                        if (res.data[0].nohp == null) res.data[0].nohp = "";
                        notelp =
                          res.data[0].notelepon + " - " + res.data[0].nohp;

                        this.formHistory
                          .get("tempatLahir")
                          .setValue(res.data[0].tempatlahir);
                        this.formHistory.get("noTelpon").setValue(notelp);
                        // this.formHistory.get('noBPJS').setValue(res.data[0].nobpjs != null ? res.data[0].nobpjs : '-')
                        this.formHistory
                          .get("noAsuransi")
                          .setValue(
                            res.data[0].nobpjs != null
                              ? res.data[0].nobpjs
                              : "-" + " / " + res.data[0].noasuransilain != null
                              ? res.data[0].noasuransilain
                              : "-"
                          );
                        this.formHistory
                          .get("alamat")
                          .setValue(res.data[0].alamatlengkap);
                        this.formHistory
                          .get("pekerjaan")
                          .setValue(res.data[0].pekerjaan);
                        this.formHistory
                          .get("pendidikan")
                          .setValue(res.data[0].pendidikan);
                        this.dataSource = res.data;

                        this.formGroup.reset();
                      } else {
                        this.dataSource = [];
                        this.isCollalsedPasien = true;
                        // this.messageService.info('Info', 'Data tidak ada')
                      }
                      this.activeItem = {
                        icon: "fa fa-fw fa-history",
                        label: "History",
                      };

                      this.cetakHistory(res.data[0]);
                      this.simpanVisible = true;
                    },
                    (error) => {
                      this.isCollalsedPasien = true;
                      this.dataSource = [];
                      this.loadingHistory = false;
                      this.simpanVisible = true;
                      // this.messageService.info('Info', 'Data tidak ada')
                    }
                  );

                // this.formGroup.reset()
              },
              (error) => {
                this.simpanVisible = true;
              }
            );
        } else {
          debugger
          this.httpService
            .post(
              "medifirst2000/reservasionline/save-rotest",
              this.formGroup.value
            )
            .subscribe(
              (res) => {
                if (this.isBaru) {
                  this.formHistory
                    .get("noCm")
                    .setValue(this.formGroup.get("namaPasien").value);
                } else {
                  this.formHistory
                    .get("noCm")
                    .setValue(this.formGroup.get("noCm").value);
                }
                this.formHistory
                  .get("tglLahir")
                  .setValue(this.formGroup.get("tglLahir").value);
                let tglLahirLama = moment(
                  this.formHistory.get("tglLahir").value
                ).format("DD-MM-YYYY");
                this.httpService
                  .get(
                    "medifirst2000/reservasionline/get-history?nocmNama=" +
                      this.formHistory.get("noCm").value +
                      "&tgllahir=" +
                      tglLahirLama
                  )
                  .subscribe(
                    (res) => {
                      this.loadingHistory = false;
                      if (res.data.length > 0) {
                        this.isCollalsedPasien = false;
                        // for (let i = 0; i < res.data.length; i++) {
                        //   const element = res.data[i];
                        //   let tglReserDay = new Date(element.tanggalreservasi)
                        //   let _hari = tglReserDay.getDay();
                        //   let hariFix = this.listHari[_hari]
                        //   element.tanggalreservasi = hariFix + ', ' + moment(new Date(element.tanggalreservasi)).format('DD-MMM-YYYY HH:mm') //HH:mm
                        //   // element.tanggalreservasi = moment(new Date(element.tanggalreservasi)).format('DD-MMM-YYYY HH:mm')
                        // }
                        let namaPasien = res.data[0].namapasien;
                        let subStr = namaPasien.substring(0, 5);

                        this.formHistory
                          .get("namaPasien")
                          .setValue(subStr + "********");
                        this.formHistory
                          .get("jenisKelamin")
                          .setValue(res.data[0].jeniskelamin);
                        let notelp = "";
                        if (res.data[0].notelepon == null)
                          res.data[0].notelepon = "";
                        if (res.data[0].nohp == null) res.data[0].nohp = "";
                        notelp =
                          res.data[0].notelepon + " - " + res.data[0].nohp;

                        this.formHistory
                          .get("tempatLahir")
                          .setValue(res.data[0].tempatlahir);
                        this.formHistory.get("noTelpon").setValue(notelp);
                        // this.formHistory.get('noBPJS').setValue(res.data[0].nobpjs != null ? res.data[0].nobpjs : '-')
                        this.formHistory
                          .get("noAsuransi")
                          .setValue(
                            res.data[0].nobpjs != null
                              ? res.data[0].nobpjs
                              : "-" + " / " + res.data[0].noasuransilain != null
                              ? res.data[0].noasuransilain
                              : "-"
                          );
                        this.formHistory
                          .get("alamat")
                          .setValue(res.data[0].alamatlengkap);
                        this.formHistory
                          .get("pekerjaan")
                          .setValue(res.data[0].pekerjaan);
                        this.formHistory
                          .get("pendidikan")
                          .setValue(res.data[0].pendidikan);
                        this.dataSource = res.data;

                        this.formGroup.reset();
                      } else {
                        this.dataSource = [];
                        this.isCollalsedPasien = true;
                        // this.messageService.info('Info', 'Data tidak ada')
                      }
                      this.activeItem = {
                        icon: "fa fa-fw fa-history",
                        label: "History",
                      };

                      this.cetakHistory(res.data[0]);
                      this.simpanVisible = true;
                    },
                    (error) => {
                      this.isCollalsedPasien = true;
                      this.dataSource = [];
                      this.loadingHistory = false;
                      this.simpanVisible = true;
                      // this.messageService.info('Info', 'Data tidak ada')
                    }
                  );

                // this.formGroup.reset()
              },
              (error) => {
                this.simpanVisible = true;
              }
            );
        }
      },
    });
  }

  savetelemedicine() {
    this.formGroupTelemedicine
      .get("tglLahir")
      .setValue(
        moment(this.formGroupTelemedicine.get("tglLahirLama").value).format(
          "YYYY-MM-DD"
        )
      );

    let tglReservasi = this.formGroupTelemedicine.get("tglReservasi").value;
    tglReservasi = new Date(
      tglReservasi._i.year,
      tglReservasi._i.month,
      tglReservasi._i.date
    );
    var tanggal: any = moment(tglReservasi).format("YYYY-MM-DD") + " 00:00";
    //console.log(tanggal)
    this.formGroupTelemedicine.get("tglReservasiFix").setValue(tanggal);

    // this.formGroupTelemedicine.get('dokter').setValue({ id: this.formGroupTelemedicine.get('dokter').value.id, namalengkap: this.formGroupTelemedicine.get('dokter').value.namalengkap })
    this.formGroupTelemedicine.get("dokter").setValue(null);
    this.confirmationService.confirm({
      message: "Yakin mau menyimpan data?",
      accept: () => {
        this.simpanVisible = false;
        this.httpService
          .post(
            "medifirst2000/reservasionline/save",
            this.formGroupTelemedicine.value
          )
          .subscribe(
            (res) => {
              this.formHistory
                .get("noCm")
                .setValue(this.formGroupTelemedicine.get("noCm").value);
              this.formHistory
                .get("tglLahir")
                .setValue(this.formGroupTelemedicine.get("tglLahir").value);
              let tglLahirLama = moment(
                this.formHistory.get("tglLahir").value
              ).format("DD-MM-YYYY");
              this.httpService
                .get(
                  "medifirst2000/reservasionline/get-history?nocmNama=" +
                    this.formHistory.get("noCm").value +
                    "&tgllahir=" +
                    tglLahirLama
                )
                .subscribe(
                  (res) => {
                    this.loadingHistory = false;
                    if (res.data.length > 0) {
                      this.isCollalsedPasien = false;
                      for (let i = 0; i < res.data.length; i++) {
                        const element = res.data[i];
                        let tglReserDay = new Date(element.tanggalreservasi);
                        let _hari = tglReserDay.getDay();
                        let hariFix = this.listHari[_hari];
                        element.tanggalreservasi =
                          hariFix +
                          ", " +
                          moment(new Date(element.tanggalreservasi)).format(
                            "DD-MMM-YYYY HH:mm"
                          ); //HH:mm
                        // element.tanggalreservasi = moment(new Date(element.tanggalreservasi)).format('DD-MMM-YYYY HH:mm')
                      }
                      let namaPasien = res.data[0].namapasien;
                      let subStr = namaPasien.substring(0, 5);

                      this.formHistory
                        .get("namaPasien")
                        .setValue(subStr + "********");
                      this.formHistory
                        .get("jenisKelamin")
                        .setValue(res.data[0].jeniskelamin);
                      let notelp = "";
                      if (res.data[0].notelepon == null)
                        res.data[0].notelepon = "";
                      if (res.data[0].nohp == null) res.data[0].nohp = "";
                      notelp = res.data[0].notelepon + " - " + res.data[0].nohp;

                      this.formHistory
                        .get("tempatLahir")
                        .setValue(res.data[0].tempatlahir);
                      this.formHistory.get("noTelpon").setValue(notelp);
                      this.formHistory.get("noTelpon").setValue(notelp);
                      // this.formHistory.get('noBPJS').setValue(res.data[0].nobpjs != null ? res.data[0].nobpjs : '-')
                      this.formHistory
                        .get("noAsuransi")
                        .setValue(
                          res.data[0].nobpjs != null
                            ? res.data[0].nobpjs
                            : "-" + " / " + res.data[0].noasuransilain != null
                            ? res.data[0].noasuransilain
                            : "-"
                        );
                      this.formHistory
                        .get("alamat")
                        .setValue(res.data[0].alamatlengkap);
                      this.formHistory
                        .get("pekerjaan")
                        .setValue(res.data[0].pekerjaan);
                      this.formHistory
                        .get("pendidikan")
                        .setValue(res.data[0].pendidikan);
                      this.dataSource = res.data;

                      this.formGroupTelemedicine.reset();
                    } else {
                      this.dataSource = [];
                      this.isCollalsedPasien = true;
                      // this.messageService.info('Info', 'Data tidak ada')
                    }
                    this.activeItem = {
                      icon: "fa fa-fw fa-history",
                      label: "History",
                    };
                    this.isTelemedicine = true;
                    this.cetakHistory(res.data[0]);
                    this.simpanVisible = true;
                  },
                  (error) => {
                    this.isCollalsedPasien = true;
                    this.dataSource = [];
                    this.loadingHistory = false;
                    this.simpanVisible = true;
                    // this.messageService.info('Info', 'Data tidak ada')
                  }
                );

              // this.formGroupTelemedicine.reset()
            },
            (error) => {
              this.simpanVisible = true;
            }
          );
      },
    });
  }

  popUpQR() {
    this.displayPopUpQr = true;
  }

  getSlotting(event) {
    debugger;
    //ketika ganti tanggal data yang lain dibuat kosong
    this.formGroup.get('poliKlinik').setValue('')
    this.formGroup.get('tipePembayaran').setValue('')
    this.formGroup.get('namaPenjamin').setValue('')
    this.formGroup.get('jamReservasi').setValue('')
    this.formGroup.get('asalRujukan').setValue('')
    this.formGroup.get("dokter").setValue('')
    this.isBPJS = false
    // this.showtglasalrujukan = true
    this.showtipepembayaran = false
    this.showpolikinik = false
    this.showtglasalrujukan = true;
    this.showdokter = false
    this.ListisBPJSnorujukan = false
  
    var tglReservasi = this.formGroup.get("tglReservasi").value;
    if (!tglReservasi) {
      this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
      return;
    }

    // tglReservasi = new Date(
    //   tglReservasi._i.year,
    //   tglReservasi._i.month,
    //   tglReservasi._i.date
    // );var tglInput = event.value._d
    var tanggalInput: any = moment(event.value._d).format("YYYY-MM-DD 00:00:00");
    this.tglInput = tanggalInput;
    this.httpService.get(`medifirst2000/reservasionline/get-slotting-libur-rs?tanggalInput=${tanggalInput}`)
    .subscribe((res) => {
        if(res.data.length > 0 ) {
          const tanggalLiburAwal = moment(res.data[0].tanggal_libur_awal).format('YYYY-MM-DD 00:00:00');
          const tanggalLiburAkhir = moment(res.data[0].tanggal_libur_akhir).format('YYYY-MM-DD 23:59:59');
          console.log({
            tanggalInput: tanggalInput,
            tanggalLiburAkhir: tanggalLiburAkhir,
            tanggalLiburAwal: tanggalLiburAwal
          });
          
          if(Date.parse(tanggalInput) >= Date.parse(tanggalLiburAwal) && Date.parse(tanggalInput)  <= Date.parse(tanggalLiburAkhir)) {
            console.log("penjagaan masuk");
            this.showtglasalrujukan = false;
            this.messageService.error("Maaf Rumah sakit libur, "  + res.data[0].keterangan, res.message);
            return;
          }
        }
    });
    

    let idRuangan = event.value.id;
    if (idRuangan == undefined) {
      idRuangan =
        this.formGroup.get("poliKlinik").value != null
          ? this.formGroup.get("poliKlinik").value.id
          : undefined;
    }
    if (idRuangan == undefined) {
      
      return;
    }
    // this.formGroup.get('jamReservasi').reset()
    // let tglReservasi = moment(this.formGroup.get('tglReservasi').value).format('YYYY-MM-DD')
    var tglReservasi = this.formGroup.get("tglReservasi").value;
    tglReservasi = moment(
      new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      )
    ).format("YYYY-MM-DD");
    this.httpService
      .get(
        "medifirst2000/reservasionline/get-slotting-by-ruangan-new/" +
          idRuangan +
          "/" +
          tglReservasi
      )
      .subscribe(
        (res) => {
          let now = moment(new Date()).format("YYYY-MM-DD");
          let tglNow = new Date(now + " " + "13:00");
          if (this.maxJamReservasi != undefined) {
            tglNow = new Date(now + " " + this.maxJamReservasi);
          }
          if (res.slot == null) {
            this.messageService.error("Maaf", res.message);
            this.listJam = [];

            this.isSlot = true;
            return;
          }

          let tglNowNol = new Date(now + " " + "00:00");
          let tglPesen = this.formGroup.get("tglReservasi").value;
          tglPesen = new Date(
            tglPesen._i.year,
            tglPesen._i.month,
            tglPesen._i.date
          );

          let tglPesenNol = new Date(
            moment(tglPesen).format("YYYY-MM-DD 00:00")
          );
          let tglRes = tglPesen;
          let yesterday = new Date(tglRes.setDate(tglRes.getDate() - 1));
          let tglReservasis = moment(tglPesen).format("YYYY-MM-DD");
          // if (new Date() > tglNow && new Date() < new Date(tglReservasis)) {
          // ini buat penjagaan pesan besok
          // if (new Date() > tglNow && new Date() > tglPesen) {
          //   if (this.maxJamReservasi != undefined) {
          //     this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam ' + this.maxJamReservasi + ', Coba reservasi dihari diberikutnya')
          //     return
          //   }
          //   this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam 13:00, Coba reservasi dihari diberikutnya')
          //   return
          // }

          //ash 11-06-2023
          //if (tglNowNol == tglPesenNol) {
          //  this.messageService.error('Maaf', 'Tidak Bisa Pesan Untuk Tanggal Sekarang')
          //  return
          //}

          let jamBuka = res.tanggal + " " + res.slot.jambuka;
          let jamTutup = res.tanggal + " " + res.slot.jamtutup;
          let interval = res.slot.interval;
          let quota = res.slot.quota;
          this.listJam = [];
          let result = [];
          result = this.intervals(jamBuka, jamTutup, interval);

          if (result.length > 0) {
            for (let i = 0; i < quota; i++) {
              let hour: any =
                new Date(result[i]).getHours() < 10
                  ? "0" + new Date(result[i]).getHours()
                  : new Date(result[i]).getHours();
              let minutes: any =
                new Date(result[i]).getMinutes() < 10
                  ? "0" + new Date(result[i]).getMinutes()
                  : new Date(result[i]).getMinutes();
              this.listJam.push({
                jam: hour + ":" + minutes, // moment(new Date(result[i])).format('HH:mm')
              });
            }
          }
          let listReservasi = [];
          if (res.reservasi.length > 0) {
            listReservasi = res.reservasi;
          }

          if (listReservasi.length > 0) {
            // for (let j = 0; j < listReservasi.length; j++) {
            // for (let i = 0; i < this.listJam.length; i++) {
            for (var j = listReservasi.length - 1; j >= 0; j--) {
              for (var i = this.listJam.length - 1; i >= 0; i--) {
                if (this.listJam[i].jam == listReservasi[j].jamreservasi) {
                  // this.listJam.splice[i]
                  this.listJam.splice([i], 1);
                  // this.listJam[i].disable = false
                }
              }
            }
          }
          //reservasi hari ini
          let tglReservasiJam = this.formGroup.get("tglReservasi").value;
          let nowss = moment(new Date()).format("YYYY-MM-DD");
          if (
            moment(
              new Date(
                tglReservasiJam._i.year,
                tglReservasiJam._i.month,
                tglReservasiJam._i.date
              )
            ).format("YYYY-MM-DD") == nowss
          ) {
            let hourReser = moment(new Date()).format("HH:mm");
            for (var i = this.listJam.length - 1; i > 0; i--) {
              let stringJam = moment(new Date()).format(
                "YYYY-MM-DD " + this.listJam[i].jam
              );
              let stringRes = moment(new Date()).format(
                "YYYY-MM-DD " + hourReser
              );
              let dt = new Date(stringRes);
              dt.setHours(dt.getHours() + 1);
              if (new Date(stringJam) < dt) {
                // this.listJam.splice[i]
                this.listJam.splice([i], 1);
                // this.listJam[i].disable = false
              }
            }
          }
          if (this.listJam.length < 1) {
            this.messageService.error(
              "Maaf",
              "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
            );
            this.isSlot = true;
            return;
          } else {
            this.messageService.success(
              "Info",
              "Slot Reservasi Tersedia : " + this.listJam.length + " Slot"
            );
            this.isSlot = false; 
          }
          
        },
        (error) => {
          this.listJam = [];
          this.messageService.error(
            "Maaf",
            "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
          );
          this.isSlot = true;
        }
      );
  }


  // getSlotting(event) {
    
  //   //ketika ganti tanggal data yang lain dibuat kosong
  //   this.formGroup.get('poliKlinik').setValue('')
  //   this.formGroup.get('tipePembayaran').setValue('')
  //   this.formGroup.get('namaPenjamin').setValue('')
  //   this.formGroup.get('jamReservasi').setValue('')
  //   this.formGroup.get('asalRujukan').setValue('')
  //   this.formGroup.get("dokter").setValue('')
  //   this.isBPJS = false
  //   this.showtglasalrujukan = true
  //   this.showtipepembayaran = false
  //   this.showpolikinik = false
  //   this.showdokter = false
  //   this.ListisBPJSnorujukan = false

  //   var tglReservasi = this.formGroup.get("tglReservasi").value;
  //   if (!tglReservasi) {
  //     this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
  //     return;
  //   }
  //   let idRuangan = event.value.id;
  //   if (idRuangan == undefined) {
  //     idRuangan =
  //       this.formGroup.get("poliKlinik").value != null
  //         ? this.formGroup.get("poliKlinik").value.id
  //         : undefined;
  //   }
  //   if (idRuangan == undefined) {
      
  //     return;
  //   }
  //   // this.formGroup.get('jamReservasi').reset()
  //   // let tglReservasi = moment(this.formGroup.get('tglReservasi').value).format('YYYY-MM-DD')
  //   var tglReservasi = this.formGroup.get("tglReservasi").value;
  //   tglReservasi = moment(
  //     new Date(
  //       tglReservasi._i.year,
  //       tglReservasi._i.month,
  //       tglReservasi._i.date
  //     )
  //   ).format("YYYY-MM-DD");
  //   this.httpService
  //     .get(
  //       "medifirst2000/reservasionline/get-slotting-by-ruangan-new/" +
  //         idRuangan +
  //         "/" +
  //         tglReservasi
  //     )
  //     .subscribe(
  //       (res) => {
  //         let now = moment(new Date()).format("YYYY-MM-DD");
  //         let tglNow = new Date(now + " " + "13:00");
  //         if (this.maxJamReservasi != undefined) {
  //           tglNow = new Date(now + " " + this.maxJamReservasi);
  //         }
  //         if (res.slot == null) {
  //           this.messageService.error("Maaf", res.message);
  //           this.listJam = [];

  //           this.isSlot = true;
  //           return;
  //         }

  //         let tglNowNol = new Date(now + " " + "00:00");
  //         let tglPesen = this.formGroup.get("tglReservasi").value;
  //         tglPesen = new Date(
  //           tglPesen._i.year,
  //           tglPesen._i.month,
  //           tglPesen._i.date
  //         );

  //         let tglPesenNol = new Date(
  //           moment(tglPesen).format("YYYY-MM-DD 00:00")
  //         );
  //         let tglRes = tglPesen;
  //         let yesterday = new Date(tglRes.setDate(tglRes.getDate() - 1));
  //         let tglReservasis = moment(tglPesen).format("YYYY-MM-DD");
  //         // if (new Date() > tglNow && new Date() < new Date(tglReservasis)) {
  //         // ini buat penjagaan pesan besok
  //         // if (new Date() > tglNow && new Date() > tglPesen) {
  //         //   if (this.maxJamReservasi != undefined) {
  //         //     this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam ' + this.maxJamReservasi + ', Coba reservasi dihari diberikutnya')
  //         //     return
  //         //   }
  //         //   this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam 13:00, Coba reservasi dihari diberikutnya')
  //         //   return
  //         // }

  //         //ash 11-06-2023
  //         //if (tglNowNol == tglPesenNol) {
  //         //  this.messageService.error('Maaf', 'Tidak Bisa Pesan Untuk Tanggal Sekarang')
  //         //  return
  //         //}

  //         let jamBuka = res.tanggal + " " + res.slot.jambuka;
  //         let jamTutup = res.tanggal + " " + res.slot.jamtutup;
  //         let interval = res.slot.interval;
  //         let quota = res.slot.quota;
  //         this.listJam = [];
  //         let result = [];
  //         result = this.intervals(jamBuka, jamTutup, interval);

  //         if (result.length > 0) {
  //           for (let i = 0; i < quota; i++) {
  //             let hour: any =
  //               new Date(result[i]).getHours() < 10
  //                 ? "0" + new Date(result[i]).getHours()
  //                 : new Date(result[i]).getHours();
  //             let minutes: any =
  //               new Date(result[i]).getMinutes() < 10
  //                 ? "0" + new Date(result[i]).getMinutes()
  //                 : new Date(result[i]).getMinutes();
  //             this.listJam.push({
  //               jam: hour + ":" + minutes, // moment(new Date(result[i])).format('HH:mm')
  //             });
  //           }
  //         }
  //         let listReservasi = [];
  //         if (res.reservasi.length > 0) {
  //           listReservasi = res.reservasi;
  //         }

  //         if (listReservasi.length > 0) {
  //           // for (let j = 0; j < listReservasi.length; j++) {
  //           // for (let i = 0; i < this.listJam.length; i++) {
  //           for (var j = listReservasi.length - 1; j >= 0; j--) {
  //             for (var i = this.listJam.length - 1; i >= 0; i--) {
  //               if (this.listJam[i].jam == listReservasi[j].jamreservasi) {
  //                 // this.listJam.splice[i]
  //                 this.listJam.splice([i], 1);
  //                 // this.listJam[i].disable = false
  //               }
  //             }
  //           }
  //         }
  //         //reservasi hari ini
  //         let tglReservasiJam = this.formGroup.get("tglReservasi").value;
  //         let nowss = moment(new Date()).format("YYYY-MM-DD");
  //         if (
  //           moment(
  //             new Date(
  //               tglReservasiJam._i.year,
  //               tglReservasiJam._i.month,
  //               tglReservasiJam._i.date
  //             )
  //           ).format("YYYY-MM-DD") == nowss
  //         ) {
  //           let hourReser = moment(new Date()).format("HH:mm");
  //           for (var i = this.listJam.length - 1; i > 0; i--) {
  //             let stringJam = moment(new Date()).format(
  //               "YYYY-MM-DD " + this.listJam[i].jam
  //             );
  //             let stringRes = moment(new Date()).format(
  //               "YYYY-MM-DD " + hourReser
  //             );
  //             let dt = new Date(stringRes);
  //             dt.setHours(dt.getHours() + 1);
  //             if (new Date(stringJam) < dt) {
  //               // this.listJam.splice[i]
  //               this.listJam.splice([i], 1);
  //               // this.listJam[i].disable = false
  //             }
  //           }
  //         }
  //         if (this.listJam.length < 1) {
  //           this.messageService.error(
  //             "Maaf",
  //             "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
  //           );
  //           this.isSlot = true;
  //           return;
  //         } else {
  //           this.messageService.success(
  //             "Info",
  //             "Slot Reservasi Tersedia : " + this.listJam.length + " Slot"
  //           );
  //           this.isSlot = false; 
  //         }
          
  //       },
  //       (error) => {
  //         this.listJam = [];
  //         this.messageService.error(
  //           "Maaf",
  //           "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
  //         );
  //         this.isSlot = true;
  //       }
  //     );

  // }

  //ash 2023-05-17
  
  getSlottingEdit(event) {
    
    //ketika ganti tanggal data yang lain dibuat kosong
    //this.formGroup.get('poliKlinik').setValue('')
    //this.formGroup.get('tipePembayaran').setValue('')
    //this.formGroup.get('namaPenjamin').setValue('')
    //this.formGroup.get('jamReservasi').setValue('')
    //this.formGroup.get('asalRujukan').setValue('')
    //this.formGroup.get("dokter").setValue('')
    this.isBPJS = false
    this.showtglasalrujukan = true
    this.showtipepembayaran = false
    this.showpolikinik = false
    this.showdokter = false
    this.ListisBPJSnorujukan = false

    var tglReservasi = this.formGroup.get("tglReservasi").value;
    if (!tglReservasi) {
      this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
      return;
    }
    let idRuangan = event.value.id;
    if (idRuangan == undefined) {
      idRuangan =
        this.formGroup.get("poliKlinik").value != null
          ? this.formGroup.get("poliKlinik").value.id
          : undefined;
    }
    if (idRuangan == undefined) {
      
      return;
    }
    // this.formGroup.get('jamReservasi').reset()
    // let tglReservasi = moment(this.formGroup.get('tglReservasi').value).format('YYYY-MM-DD')
    var tglReservasi = this.formGroup.get("tglReservasi").value;
    tglReservasi = moment(
      new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      )
    ).format("YYYY-MM-DD");
    this.httpService
      .get(
        "medifirst2000/reservasionline/get-slotting-by-ruangan-new/" +
          idRuangan +
          "/" +
          tglReservasi
      )
      .subscribe(
        (res) => {
          let now = moment(new Date()).format("YYYY-MM-DD");
          let tglNow = new Date(now + " " + "13:00");
          if (this.maxJamReservasi != undefined) {
            tglNow = new Date(now + " " + this.maxJamReservasi);
          }
          if (res.slot == null) {
            this.messageService.error("Maaf", res.message);
            this.listJam = [];

            this.isSlot = true;
            return;
          }

          let tglNowNol = new Date(now + " " + "00:00");
          let tglPesen = this.formGroup.get("tglReservasi").value;
          tglPesen = new Date(
            tglPesen._i.year,
            tglPesen._i.month,
            tglPesen._i.date
          );

          let tglPesenNol = new Date(
            moment(tglPesen).format("YYYY-MM-DD 00:00")
          );
          let tglRes = tglPesen;
          let yesterday = new Date(tglRes.setDate(tglRes.getDate() - 1));
          let tglReservasis = moment(tglPesen).format("YYYY-MM-DD");
          // if (new Date() > tglNow && new Date() < new Date(tglReservasis)) {
          // ini buat penjagaan pesan besok
          // if (new Date() > tglNow && new Date() > tglPesen) {
          //   if (this.maxJamReservasi != undefined) {
          //     this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam ' + this.maxJamReservasi + ', Coba reservasi dihari diberikutnya')
          //     return
          //   }
          //   this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam 13:00, Coba reservasi dihari diberikutnya')
          //   return
          // }

          //ash 11-06-2023
          //if (tglNowNol == tglPesenNol) {
          //  this.messageService.error('Maaf', 'Tidak Bisa Pesan Untuk Tanggal Sekarang')
          //  return
          //}

          let jamBuka = res.tanggal + " " + res.slot.jambuka;
          let jamTutup = res.tanggal + " " + res.slot.jamtutup;
          let interval = res.slot.interval;
          let quota = res.slot.quota;
          this.listJam = [];
          let result = [];
          result = this.intervals(jamBuka, jamTutup, interval);

          if (result.length > 0) {
            for (let i = 0; i < quota; i++) {
              let hour: any =
                new Date(result[i]).getHours() < 10
                  ? "0" + new Date(result[i]).getHours()
                  : new Date(result[i]).getHours();
              let minutes: any =
                new Date(result[i]).getMinutes() < 10
                  ? "0" + new Date(result[i]).getMinutes()
                  : new Date(result[i]).getMinutes();
              this.listJam.push({
                jam: hour + ":" + minutes, // moment(new Date(result[i])).format('HH:mm')
              });
            }
          }
          let listReservasi = [];
          if (res.reservasi.length > 0) {
            listReservasi = res.reservasi;
          }

          if (listReservasi.length > 0) {
            // for (let j = 0; j < listReservasi.length; j++) {
            // for (let i = 0; i < this.listJam.length; i++) {
            for (var j = listReservasi.length - 1; j >= 0; j--) {
              for (var i = this.listJam.length - 1; i >= 0; i--) {
                if (this.listJam[i].jam == listReservasi[j].jamreservasi) {
                  // this.listJam.splice[i]
                  this.listJam.splice([i], 1);
                  // this.listJam[i].disable = false
                }
              }
            }
          }
          //reservasi hari ini
          let tglReservasiJam = this.formGroup.get("tglReservasi").value;
          let nowss = moment(new Date()).format("YYYY-MM-DD");
          if (
            moment(
              new Date(
                tglReservasiJam._i.year,
                tglReservasiJam._i.month,
                tglReservasiJam._i.date
              )
            ).format("YYYY-MM-DD") == nowss
          ) {
            let hourReser = moment(new Date()).format("HH:mm");
            for (var i = this.listJam.length - 1; i > 0; i--) {
              let stringJam = moment(new Date()).format(
                "YYYY-MM-DD " + this.listJam[i].jam
              );
              let stringRes = moment(new Date()).format(
                "YYYY-MM-DD " + hourReser
              );
              let dt = new Date(stringRes);
              dt.setHours(dt.getHours() + 1);
              if (new Date(stringJam) < dt) {
                // this.listJam.splice[i]
                this.listJam.splice([i], 1);
                // this.listJam[i].disable = false
              }
            }
          }
          if (this.listJam.length < 1) {
            this.messageService.error(
              "Maaf",
              "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
            );
            this.isSlot = true;
            return;
          } else {
            this.messageService.success(
              "Info",
              "Slot Reservasi Tersedia : " + this.listJam.length + " Slot"
            );
            this.isSlot = false; 
          }
          
        },
        (error) => {
          this.listJam = [];
          this.messageService.error(
            "Maaf",
            "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
          );
          this.isSlot = true;
        }
      );

  }

  
  // Slotting Poli Dari Sistem new baru fresh toha yg bikin 
  getSlottingPoli(event) {
    var tglReservasi = this.formGroup.get("tglReservasi").value;
    if (!tglReservasi) {
      this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
      return;
    }
    let idRuangan = event.value.id;
    if (idRuangan == undefined) {
      idRuangan =
        this.formGroup.get("poliKlinik").value != null
          ? this.formGroup.get("poliKlinik").value.id
          : undefined;
    }
    if (idRuangan == undefined) {
      return;
    }
    // this.formGroup.get('jamReservasi').reset()
    // let tglReservasi = moment(this.formGroup.get('tglReservasi').value).format('YYYY-MM-DD')
    var tglReservasi = this.formGroup.get("tglReservasi").value;
    tglReservasi = moment(
      new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      )
    ).format("YYYY-MM-DD");
    this.httpService
      .get(
        `medifirst2000/reservasionline/get-slotting-ruangan-poli?idRuangan=${idRuangan}&tanggal=${tglReservasi}`
      )
      .subscribe(
        (res) => {
          // let now = moment(new Date()).format("YYYY-MM-DD");
          // let tglNow = new Date(now + " " + "13:00");
          // if (this.maxJamReservasi != undefined) {
          //   tglNow = new Date(now + " " + this.maxJamReservasi);
          // }
          console.log(res);
          
          if (res.messageCode == 500) {
            this.messageService.error("Maaf ", res.message);
            this.formGroup.get("tglReservasi").setValue("");
            this.listJam = [];
            this.isSlot = true;
            return;
          } else {
            this.httpService.get(`medifirst2000/registrasi/get-dokter-part-new?idRuangan=${idRuangan}&tanggal=${tglReservasi}`)
            .subscribe((res) => {
              this.dataDokter = res.data;
              this.listDokter = [];
              this.httpService.get(`medifirst2000/reservasionline/get-slotting-libur-dokter?tanggalInput=${this.tglInput}`)
              .subscribe((result) => {
                if(result.data.length > 0) {
                  this.listDokterLibur = result.data;
                  console.log(res);
                  for (let i = 0; i < this.dataDokter.length; i++) {
                    for (let j = 0; j < this.listDokterLibur.length; j++) {
                      if(this.dataDokter[i].id != this.listDokterLibur.id)
                      this.listDokter.push({
                        id: this.dataDokter[i].id,
                        namalengkap: this.dataDokter[i].namalengkap,
                        idhafis: this.dataDokter[i].idhafis,
                        namahafis: this.dataDokter[i].namahafis,
                        jadwal: `${this.dataDokter[i].jammulai} - ${this.dataDokter[i].jamakhir}`,
                        kodesubspesialis: "-",
                        kapasitaspasien: this.dataDokter[i].quota
                      });
                    }
                  }
                  this.messageService.success("Oke ", result.message);
                    this.isSlot = false;
                } else {
                  for (let i = 0; i < this.dataDokter.length; i++) {
                    this.listDokter.push({
                      id: this.dataDokter[i].id,
                      namalengkap: this.dataDokter[i].namalengkap,
                      idhafis: this.dataDokter[i].idhafis,
                      namahafis: this.dataDokter[i].namahafis,
                      jadwal: `${this.dataDokter[i].jammulai} - ${this.dataDokter[i].jamakhir}`,
                      kodesubspesialis: "-",
                      kapasitaspasien: this.dataDokter[i].quota
                    });
                  }
                  this.messageService.success("Oke ", result.message);
                    this.isSlot = false;
                }
              });
            });
          }

          // let tglNowNol = new Date(now + " " + "00:00");
          // let tglPesen = this.formGroup.get("tglReservasi").value;
          // tglPesen = new Date(
          //   tglPesen._i.year,
          //   tglPesen._i.month,
          //   tglPesen._i.date
          // );

          // let tglPesenNol = new Date(
          //   moment(tglPesen).format("YYYY-MM-DD 00:00")
          // );
          // let tglRes = tglPesen;
          // let yesterday = new Date(tglRes.setDate(tglRes.getDate() - 1));
          // let tglReservasis = moment(tglPesen).format("YYYY-MM-DD");
          // if (new Date() > tglNow && new Date() < new Date(tglReservasis)) {
          // ini buat penjagaan pesan besok
          // if (new Date() > tglNow && new Date() > tglPesen) {
          //   if (this.maxJamReservasi != undefined) {
          //     this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam ' + this.maxJamReservasi + ', Coba reservasi dihari diberikutnya')
          //     return
          //   }
          //   this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam 13:00, Coba reservasi dihari diberikutnya')
          //   return
          // }

          //ash 11-06-2023
          //if (tglNowNol == tglPesenNol) {
          //  this.messageService.error('Maaf', 'Tidak Bisa Pesan Untuk Tanggal Sekarang')
          //  return
          //}

          // let jamBuka = res.tanggal + " " + res.slot.jambuka;
          // let jamTutup = res.tanggal + " " + res.slot.jamtutup;
          // let interval = res.slot.interval;
          // let quota = res.slot.quota;
          // this.listJam = [];
          // let result = [];
          // result = this.intervals(jamBuka, jamTutup, interval);

          // if (result.length > 0) {
          //   for (let i = 0; i < quota; i++) {
          //     let hour: any =
          //       new Date(result[i]).getHours() < 10
          //         ? "0" + new Date(result[i]).getHours()
          //         : new Date(result[i]).getHours();
          //     let minutes: any =
          //       new Date(result[i]).getMinutes() < 10
          //         ? "0" + new Date(result[i]).getMinutes()
          //         : new Date(result[i]).getMinutes();
          //     this.listJam.push({
          //       jam: hour + ":" + minutes, // moment(new Date(result[i])).format('HH:mm')
          //     });
          //   }
          // }
          // let listReservasi = [];
          // if (res.reservasi.length > 0) {
          //   listReservasi = res.reservasi;
          //   if(listReservasi.length >= res.slot.quota){
          //     this.messageService.error('Maaf', 'Jadwal Reservasi Sudah Penuh, Coba dihari lain')
          //     this.formGroup.get("tglReservasi").setValue("");
          //     return;              
          //   }
          // }

          // if (listReservasi.length > 0) {
          //   // for (let j = 0; j < listReservasi.length; j++) {
          //   // for (let i = 0; i < this.listJam.length; i++) {
          //   for (var j = listReservasi.length - 1; j >= 0; j--) {
          //     for (var i = this.listJam.length - 1; i >= 0; i--) {
          //       if (this.listJam[i].jam == listReservasi[j].jamreservasi) {
          //         // this.listJam.splice[i]
          //         this.listJam.splice([i], 1);
          //         // this.listJam[i].disable = false
          //       }
          //     }
          //   }
          // }
          //reservasi hari ini
          // let tglReservasiJam = this.formGroup.get("tglReservasi").value;
          // let nowss = moment(new Date()).format("YYYY-MM-DD");
          // if (
          //   moment(
          //     new Date(
          //       tglReservasiJam._i.year,
          //       tglReservasiJam._i.month,
          //       tglReservasiJam._i.date
          //     )
          //   ).format("YYYY-MM-DD") == nowss
          // ) {
          //   let hourReser = moment(new Date()).format("HH:mm");
          //   for (var i = this.listJam.length - 1; i > 0; i--) {
          //     let stringJam = moment(new Date()).format(
          //       "YYYY-MM-DD " + this.listJam[i].jam
          //     );
          //     let stringRes = moment(new Date()).format(
          //       "YYYY-MM-DD " + hourReser
          //     );
          //     let dt = new Date(stringRes);
          //     dt.setHours(dt.getHours() + 1);
          //     if (new Date(stringJam) < dt) {
          //       // this.listJam.splice[i]
          //       this.listJam.splice([i], 1);
          //       // this.listJam[i].disable = false
          //     }
          //   }
          // }
          // if (this.listJam.length < 1) {
          //   this.messageService.error(
          //     "Maaf",
          //     "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
          //   );
          //   this.isSlot = true;
          // } else {
          //   this.messageService.success(
          //     "Info",
          //     "Slot Reservasi Tersedia : " + this.listJam.length + " Slot"
          //   );
          //   this.isSlot = false;
          // }
          // this.getSlotting2(this.formGroup.get("poliKlinik"));
        },
        (error) => {
          this.listJam = [];
          this.messageService.error(
            "Maaf",
            "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
          );
          this.isSlot = true;
        }
      );
      this.showdokter = true;
  }

  // getSlottingPoli(event) {
  //   var tglReservasi = this.formGroup.get("tglReservasi").value;
  //   if (!tglReservasi) {
  //     this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
  //     return;
  //   }
  //   let idRuangan = event.value.id;
  //   if (idRuangan == undefined) {
  //     idRuangan =
  //       this.formGroup.get("poliKlinik").value != null
  //         ? this.formGroup.get("poliKlinik").value.id
  //         : undefined;
  //   }
  //   if (idRuangan == undefined) {
  //     return;
  //   }
  //   // this.formGroup.get('jamReservasi').reset()
  //   // let tglReservasi = moment(this.formGroup.get('tglReservasi').value).format('YYYY-MM-DD')
  //   var tglReservasi = this.formGroup.get("tglReservasi").value;
  //   tglReservasi = moment(
  //     new Date(
  //       tglReservasi._i.year,
  //       tglReservasi._i.month,
  //       tglReservasi._i.date
  //     )
  //   ).format("YYYY-MM-DD");
  //   this.httpService
  //     .get(
  //       "medifirst2000/reservasionline/get-slotting-by-ruangan-new/" +
  //         idRuangan +
  //         "/" +
  //         tglReservasi
  //     )
  //     .subscribe(
  //       (res) => {
  //         let now = moment(new Date()).format("YYYY-MM-DD");
  //         let tglNow = new Date(now + " " + "13:00");
  //         if (this.maxJamReservasi != undefined) {
  //           tglNow = new Date(now + " " + this.maxJamReservasi);
  //         }
  //         if (res.slot == null) {
  //           this.messageService.error("Maaf", res.message);
  //           this.listJam = [];

  //           this.isSlot = true;
  //           return;
  //         }

  //         let tglNowNol = new Date(now + " " + "00:00");
  //         let tglPesen = this.formGroup.get("tglReservasi").value;
  //         tglPesen = new Date(
  //           tglPesen._i.year,
  //           tglPesen._i.month,
  //           tglPesen._i.date
  //         );

  //         let tglPesenNol = new Date(
  //           moment(tglPesen).format("YYYY-MM-DD 00:00")
  //         );
  //         let tglRes = tglPesen;
  //         let yesterday = new Date(tglRes.setDate(tglRes.getDate() - 1));
  //         let tglReservasis = moment(tglPesen).format("YYYY-MM-DD");
  //         // if (new Date() > tglNow && new Date() < new Date(tglReservasis)) {
  //         // ini buat penjagaan pesan besok
  //         // if (new Date() > tglNow && new Date() > tglPesen) {
  //         //   if (this.maxJamReservasi != undefined) {
  //         //     this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam ' + this.maxJamReservasi + ', Coba reservasi dihari diberikutnya')
  //         //     return
  //         //   }
  //         //   this.messageService.error('Maaf', 'Jadwal Reservasi untuk besok maksimal dipesan sebelum jam 13:00, Coba reservasi dihari diberikutnya')
  //         //   return
  //         // }

  //         //ash 11-06-2023
  //         //if (tglNowNol == tglPesenNol) {
  //         //  this.messageService.error('Maaf', 'Tidak Bisa Pesan Untuk Tanggal Sekarang')
  //         //  return
  //         //}

  //         let jamBuka = res.tanggal + " " + res.slot.jambuka;
  //         let jamTutup = res.tanggal + " " + res.slot.jamtutup;
  //         let interval = res.slot.interval;
  //         let quota = res.slot.quota;
  //         this.listJam = [];
  //         let result = [];
  //         result = this.intervals(jamBuka, jamTutup, interval);

  //         if (result.length > 0) {
  //           for (let i = 0; i < quota; i++) {
  //             let hour: any =
  //               new Date(result[i]).getHours() < 10
  //                 ? "0" + new Date(result[i]).getHours()
  //                 : new Date(result[i]).getHours();
  //             let minutes: any =
  //               new Date(result[i]).getMinutes() < 10
  //                 ? "0" + new Date(result[i]).getMinutes()
  //                 : new Date(result[i]).getMinutes();
  //             this.listJam.push({
  //               jam: hour + ":" + minutes, // moment(new Date(result[i])).format('HH:mm')
  //             });
  //           }
  //         }
  //         let listReservasi = [];
  //         if (res.reservasi.length > 0) {
  //           listReservasi = res.reservasi;
  //           if(listReservasi.length >= res.slot.quota){
  //             this.messageService.error('Maaf', 'Jadwal Reservasi Sudah Penuh, Coba dihari lain')
  //             this.formGroup.get("tglReservasi").setValue("");
  //             return;              
  //           }
  //         }

  //         if (listReservasi.length > 0) {
  //           // for (let j = 0; j < listReservasi.length; j++) {
  //           // for (let i = 0; i < this.listJam.length; i++) {
  //           for (var j = listReservasi.length - 1; j >= 0; j--) {
  //             for (var i = this.listJam.length - 1; i >= 0; i--) {
  //               if (this.listJam[i].jam == listReservasi[j].jamreservasi) {
  //                 // this.listJam.splice[i]
  //                 this.listJam.splice([i], 1);
  //                 // this.listJam[i].disable = false
  //               }
  //             }
  //           }
  //         }
  //         //reservasi hari ini
  //         let tglReservasiJam = this.formGroup.get("tglReservasi").value;
  //         let nowss = moment(new Date()).format("YYYY-MM-DD");
  //         if (
  //           moment(
  //             new Date(
  //               tglReservasiJam._i.year,
  //               tglReservasiJam._i.month,
  //               tglReservasiJam._i.date
  //             )
  //           ).format("YYYY-MM-DD") == nowss
  //         ) {
  //           let hourReser = moment(new Date()).format("HH:mm");
  //           for (var i = this.listJam.length - 1; i > 0; i--) {
  //             let stringJam = moment(new Date()).format(
  //               "YYYY-MM-DD " + this.listJam[i].jam
  //             );
  //             let stringRes = moment(new Date()).format(
  //               "YYYY-MM-DD " + hourReser
  //             );
  //             let dt = new Date(stringRes);
  //             dt.setHours(dt.getHours() + 1);
  //             if (new Date(stringJam) < dt) {
  //               // this.listJam.splice[i]
  //               this.listJam.splice([i], 1);
  //               // this.listJam[i].disable = false
  //             }
  //           }
  //         }
  //         if (this.listJam.length < 1) {
  //           this.messageService.error(
  //             "Maaf",
  //             "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
  //           );
  //           this.isSlot = true;
  //         } else {
  //           this.messageService.success(
  //             "Info",
  //             "Slot Reservasi Tersedia : " + this.listJam.length + " Slot"
  //           );
  //           this.isSlot = false;
  //         }
  //         this.getSlotting2(this.formGroup.get("poliKlinik"));
  //       },
  //       (error) => {
  //         this.listJam = [];
  //         this.messageService.error(
  //           "Maaf",
  //           "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
  //         );
  //         this.isSlot = true;
  //       }
  //     );
  //     this.showdokter = true;
  // }

  getSlottingTelemedicine(event) {
    var tglReservasi = this.formGroupTelemedicine.get("tglReservasi").value;
    if (!tglReservasi) {
      this.messageService.warn("Peringatan", "Tgl Reservasi harus di isi !");
      return;
    }
    let idRuangan = event.value.id;
    if (idRuangan == undefined) {
      idRuangan =
        this.formGroupTelemedicine.get("poliKlinik").value != null
          ? this.formGroupTelemedicine.get("poliKlinik").value.id
          : undefined;
    }
    if (idRuangan == undefined) {
      return;
    }
    this.formGroupTelemedicine.get("jamReservasi").reset();
    // let tglReservasi = moment(this.formGroupTelemedicine.get('tglReservasi').value).format('YYYY-MM-DD')
    var tglReservasi = this.formGroupTelemedicine.get("tglReservasi").value;
    tglReservasi = moment(
      new Date(
        tglReservasi._i.year,
        tglReservasi._i.month,
        tglReservasi._i.date
      )
    ).format("YYYY-MM-DD");
    this.httpService
      .get(
        "medifirst2000/reservasionline/get-slotting-by-ruangan-new/" +
          idRuangan +
          "/" +
          tglReservasi
      )
      .subscribe(
        (res) => {
          let now = moment(new Date()).format("YYYY-MM-DD");
          let tglNow = new Date(now + " " + "13:00");
          if (this.maxJamReservasi != undefined) {
            tglNow = new Date(now + " " + this.maxJamReservasi);
          }

          let tglNowNol = new Date(now + " " + "00:00");
          let tglPesen = this.formGroupTelemedicine.get("tglReservasi").value;
          tglPesen = new Date(
            tglPesen._i.year,
            tglPesen._i.month,
            tglPesen._i.date
          );

          let tglPesenNol = new Date(
            moment(tglPesen).format("YYYY-MM-DD 00:00")
          );
          let tglRes = tglPesen;
          let yesterday = new Date(tglRes.setDate(tglRes.getDate() - 1));
          let tglReservasis = moment(tglPesen).format("YYYY-MM-DD");
          // if (new Date() > tglNow && new Date() < new Date(tglReservasis)) {
          if (new Date() > tglNow && new Date() > tglPesen) {
            if (this.maxJamReservasi != undefined) {
              this.messageService.error(
                "Maaf",
                "Jadwal Reservasi untuk besok maksimal dipesan sebelum jam " +
                  this.maxJamReservasi +
                  ", Coba reservasi dihari diberikutnya"
              );
              return;
            }
            this.messageService.error(
              "Maaf",
              "Jadwal Reservasi untuk besok maksimal dipesan sebelum jam 13:00, Coba reservasi dihari diberikutnya"
            );
            return;
          }
          //ash
          //if (tglNowNol == tglPesenNol) {
          //  this.messageService.error('Maaf', 'Tidak Bisa Pesan Untuk Tanggal Sekarang')
          //  return
          //}
          let jamBuka = res.tanggal + " " + res.slot.jambuka;
          let jamTutup = res.tanggal + " " + res.slot.jamtutup;
          let interval = res.slot.interval;
          let quota = res.slot.quota;
          this.listJam = [];
          let result = [];
          result = this.intervals(jamBuka, jamTutup, interval);

          if (result.length > 0) {
            for (let i = 0; i < quota; i++) {
              let hour: any =
                new Date(result[i]).getHours() < 10
                  ? "0" + new Date(result[i]).getHours()
                  : new Date(result[i]).getHours();
              let minutes: any =
                new Date(result[i]).getMinutes() < 10
                  ? "0" + new Date(result[i]).getMinutes()
                  : new Date(result[i]).getMinutes();
              this.listJam.push({
                jam: hour + ":" + minutes, // moment(new Date(result[i])).format('HH:mm')
              });
            }
          }
          let listReservasi = [];
          if (res.reservasi.length > 0) {
            listReservasi = res.reservasi;
              if(listReservasi.length >= res.slot.quota){
                this.messageService.error('Maaf', 'Jadwal Reservasi Sudah Penuh, Coba dihari lain')
                this.formGroup.get('tglReservasi').setValue('') 
                return;               
              }
          }

          if (listReservasi.length > 0) {
            // for (let j = 0; j < listReservasi.length; j++) {
            // for (let i = 0; i < this.listJam.length; i++) {
            for (var j = listReservasi.length - 1; j >= 0; j--) {
              for (var i = this.listJam.length - 1; i >= 0; i--) {
                if (this.listJam[i].jam == listReservasi[j].jamreservasi) {
                  // this.listJam.splice[i]
                  this.listJam.splice([i], 1);
                  // this.listJam[i].disable = false
                }
              }
            }
          }
          //reservasi hari ini
          let tglReservasiJam =
            this.formGroupTelemedicine.get("tglReservasi").value;
          let nowss = moment(new Date()).format("YYYY-MM-DD");
          if (
            moment(
              new Date(
                tglReservasiJam._i.year,
                tglReservasiJam._i.month,
                tglReservasiJam._i.date
              )
            ).format("YYYY-MM-DD") == nowss
          ) {
            let hourReser = moment(new Date()).format("HH:mm");
            for (var i = this.listJam.length - 1; i > 0; i--) {
              let stringJam = moment(new Date()).format(
                "YYYY-MM-DD " + this.listJam[i].jam
              );
              let stringRes = moment(new Date()).format(
                "YYYY-MM-DD " + hourReser
              );
              let dt = new Date(stringRes);
              dt.setHours(dt.getHours() + 1);
              if (new Date(stringJam) < dt) {
                // this.listJam.splice[i]
                this.listJam.splice([i], 1);
                // this.listJam[i].disable = false
              }
            }
          }
          if (this.listJam.length < 1)
            this.messageService.error(
              "Maaf",
              "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
            );
          else
            this.messageService.success(
              "Info",
              "Slot Reservasi Tersedia : " + this.listJam.length + " Slot"
            );
        },
        (error) => {
          this.listJam = [];
          this.messageService.error(
            "Maaf",
            "Jadwal Reservasi Sudah Penuh, Coba dihari lain"
          );
        }
      );
  }
  intervals(startString, endString, interval) {
    var start = moment(startString, "YYYY-MM-DD HH:mm");
    var end = moment(endString, "YYYY-MM-DD HH:mm");

    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15);

    var result = [];

    var current = moment(start);

    while (current <= end) {
      result.push(current.format("YYYY-MM-DD HH:mm"));
      current.add(interval, "minutes");
    }

    return result;
  }
  getDaftarSlot() {
    /*var ruangan = this.formGroup.get("poliKlinik").value;
    if (!ruangan) {
      this.messageService.warn("Peringatan", "Ruangan harus di isi !");
      return;
    }*/
    let tglAwal = moment(this.formGroup.get("tglAwal").value).format(
      "YYYY-MM-DD"
    );
    let tglAkhir = moment(this.formGroup.get("tglAwal").value).format(
      "YYYY-MM-DD"
    );

    /*.get(
        "medifirst2000/reservasionline/get-slot-available?tglAwal=" +
          tglAwal +
          "&tglAkhir=" +
          tglAkhir +
          "&ruangan=" +
          ruangan.id
      )*/
    this.httpService
      .get(
        "medifirst2000/reservasionline/get-slot-available?tglAwal=" +
          tglAwal +
          "&tglAkhir=" +
          tglAkhir
      )
      .subscribe((res) => {
        this.listJam = [];
        let listslot = [];
        let listReservasi = [];
        if (res.reservasi.length > 0) {
          listReservasi = res.reservasi;
        }
        if (res.slot.length > 0) {
          listslot = res.slot;
        }

        if (listslot.length > 0) {
          for (let j = 0; j < listslot.length; j++) {
            listslot[j].sisaquota = listslot[j].quota;
            listslot[j].terisi = 0;
            for (let i = 0; i < listReservasi.length; i++) {
              if (
                listslot[j].idruangan == listReservasi[i].objectruanganlastfk
              ) {
                listslot[j].sisaquota =
                  listslot[j].quota - listReservasi[i].jmlpasien;
                listslot[j].terisi = listReservasi[i].jmlpasien;
              }
            }
          }
        }
        this.dataSlot = listslot;
      });
  }
  findPelayanan() {
    if (!this.formPelayanan.get("noRegistrasi").value) {
      this.messageService.warn("Warn", "No Registrasi harus di isi");
      return;
    }
    this.deposit = 0;
    this.totalBayar = 0;
    this.sisaTagihan = 0;
    this.totalKlaim = 0;
    this.totalTagihan = 0;
    this.item = {};

    this.httpService
      .get(
        "medifirst2000/reservasionline/tagihan/get-pasien/" +
          this.formPelayanan.get("noRegistrasi").value
      )
      .subscribe(
        (res) => {
          // this.httpService.get('medifirst2000/reservasionline/get-data-pelayanan/' + this.formPelayanan.get('noRegistrasi').value).subscribe(e => {
          let e = res.data;
          this.item.noRegistrasi = e.noregistrasi;
          this.item.noCm = e.nocm;
          this.item.namaPasien = e.namapasien.toUpperCase();
          this.item.ruangan = e.namaruangan;
          this.item.kelasRawat = e.namakelas;

          if (e.kelompokpasien.indexOf("BPJS") > -1)
            this.titleKlaim = "Tanggungan BPJS/INA-CBGs";
          else this.titleKlaim = "Total Klaim";
          let tglReg = new Date(e.tglregistrasi);
          let _hari = tglReg.getDay();
          let hariFix = this.listHari[_hari];
          e.tglregistrasi =
            hariFix +
            ", " +
            moment(new Date(e.tglregistrasi)).format("DD-MMM-YYYY HH:mm"); //HH:mm
          this.item.tglRegistrasi = e.tglregistrasi;
          if (e.tglpulang == null) {
            e.tglpulang = "-";
          } else {
            let tglPlg = new Date(e.tglpulang);
            let _haris = tglPlg.getDay();
            let hariFixx = this.listHari[_haris];
            e.tglpulang =
              hariFixx +
              ", " +
              moment(new Date(e.tglpulang)).format("DD-MMM-YYYY HH:mm"); //HH:mm
          }

          this.item.tglPulang = e.tglpulang;
          this.item.jenisPasien = e.kelompokpasien;
          // if (e.jumlahBayar == null)
          //   e.jumlahBayar = 0
          // if (e.deposit == null)
          //   e.deposit = 0
          // if (e.billing == null)
          //   e.billing = 0
          // this.deposit = this.formatRupiah(e.deposit, 'Rp. ');
          // this.totalBayar = this.formatRupiah(e.jumlahBayar, 'Rp. ');

          // let sisa = parseFloat(e.billing) - e.jumlahBayar - parseFloat(e.deposit)
          // this.sisaTagihan = this.formatRupiah(sisa, 'Rp. ');
          // this.httpService.get('valet/terbilang/' + sisa).subscribe(res => {
          //   this.totalTerbilang = res.terbilang.toUpperCase()
          // })
        },
        (error) => {
          // this.deposit = 0
          // this.totalBayar = 0
          // this.sisaTagihan = 0
          this.item = {};
        }
      );
    this.dataSourceRincian = [];
    this.httpService
      .get(
        "medifirst2000/reservasionline/get-tagihan-pasien/" +
          this.formPelayanan.get("noRegistrasi").value
      )
      .subscribe(
        (e) => {
          this.totalKlaim = e.totalklaim;
          this.deposit = e.deposit;
          this.totalBayar = 0;
          for (let i = 0; i < e.details.length; i++) {
            // if (e.details[i].sbmfk != null)
            //   this.totalBayar = this.totalBayar + e.details[i].total
            if (e.details[i].strukresepfk != null) {
              e.details[i].ruanganTindakan =
                "Pemakaian Obat & Alkes " + e.details[i].ruanganTindakan;
            }
          }
          let sama = false;
          let arrGroup = [];
          for (let i = 0; i < e.details.length; i++) {
            sama = false;
            for (let x = 0; x < arrGroup.length; x++) {
              if (arrGroup[x].ruanganTindakan == e.details[i].ruanganTindakan) {
                arrGroup[x].total =
                  parseFloat(arrGroup[x].total) +
                  parseFloat(e.details[i].total);
                sama = true;
              }
            }
            if (sama == false) {
              let result = {
                ruanganTindakan: e.details[i].ruanganTindakan,
                total: e.details[i].total,
              };
              arrGroup.push(result);
            }
          }
          let totals = 0;
          this.totalTagihan = 0;
          for (let i = 0; i < arrGroup.length; i++) {
            const element = arrGroup[i];
            totals = element.total + totals;
            this.totalTagihan = element.total + this.totalTagihan;
            element.total = this.formatRupiah(element.total, "Rp. ");
          }
          this.totalBayar = e.bayar;
          // this.totalBayar = parseFloat(this.totalTagihan) - parseFloat(this.deposit)
          this.sisaTagihan =
            parseFloat(this.totalTagihan) -
            this.totalBayar -
            parseFloat(this.deposit) -
            this.totalKlaim;

          this.httpService
            .get(
              "medifirst2000/sysadmin/general/get-terbilang/" + this.sisaTagihan
            )
            .subscribe((res) => {
              this.totalTerbilang = res.terbilang.toUpperCase();
            });
          this.totalTagihan = this.formatRupiah(this.totalTagihan, "Rp. ");
          this.deposit = this.formatRupiah(this.deposit, "Rp. ");
          this.totalBayar = this.formatRupiah(this.totalBayar, "Rp. ");
          this.sisaTagihan = this.formatRupiah(this.sisaTagihan, "Rp. ");
          this.totalKlaim = this.formatRupiah(this.totalKlaim, "Rp. ");
          arrGroup.sort(this.compare);
          this.dataSourceRincian = arrGroup;

          this.isShowRincian = false;
          // this.updateRowGroupMetaData();
        },
        (error) => {
          this.isShowRincian = true;
          this.dataSourceRincian = [];
          this.totalTerbilang = "-";
          this.deposit = 0;
          this.totalBayar = 0;
          this.sisaTagihan = 0;
          this.messageService.info("Info", "Data Tidak ditemukan");
        }
      );
  }
  compare(a, b) {
    if (a.ruanganTindakan < b.ruanganTindakan) {
      return -1;
    }
    if (a.ruanganTindakan > b.ruanganTindakan) {
      return 1;
    }
    return 0;
  }

  formatRupiah(value, currency) {
    return (
      currency +
      "" +
      parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
    );
  }
  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.dataSourceRincian) {
      for (let i = 0; i < this.dataSourceRincian.length; i++) {
        let rowData = this.dataSourceRincian[i];
        let ruanganTindakan = rowData.ruanganTindakan;
        if (i == 0) {
          this.rowGroupMetadata[ruanganTindakan] = { index: 0, size: 1 };
        } else {
          let previousRowData = this.dataSourceRincian[i - 1];
          let previousRowGroup = previousRowData.brand;
          if (ruanganTindakan === previousRowGroup)
            this.rowGroupMetadata[ruanganTindakan].size++;
          else this.rowGroupMetadata[ruanganTindakan] = { index: i, size: 1 };
        }
      }
    }
  }
  getMaxJamReservasis() {
    let maxJam = "maxJamReservasi";
    this.httpService
      .get("medifirst2000/reservasionline/get-setting?namaField=" + maxJam)
      .subscribe(
        (response) => {
          this.maxJamReservasi = response;
          console.log(response);
        },
        (error) => {}
      );
  }
  getStatusBridging() {
    let maxJam = "statusbridging";
    this.httpService
      .get("medifirst2000/reservasionline/get-setting?namaField=" + maxJam)
      .subscribe(
        (response) => {
          this.statusBridging = response;
          console.log(response);
        },
        (error) => {}
      );
  }
  pilihNoregis(event) {
    this.selectedItemGridRiwayat = event;
    this.formPelayanan.get("noRegistrasi").setValue(event.noregistrasi);
    this.isShowRiwayatRegis = false;
    this.findPelayanan();
    this.dataSourceRm = [];
  }
  tutupPopUpRm() {
    this.isShowRiwayatRegis = false;
  }
  findRiwayatRegis() {
    if (!this.formPelayanan.get("noRm").value) {
      this.messageService.warn("Info", "Masukan No Rekam Medis");
      return;
    }
    this.dataSourceRm = [];

    this.httpService
      .get(
        "medifirst2000/reservasionline/daftar-riwayat-registrasi?norm=" +
          this.formPelayanan.get("noRm").value
      )
      .subscribe(
        (response) => {
          for (let i = 0; i < response.daftar.length; i++) {
            const element = response.daftar[i];
            element.tglregistrasi = moment(
              new Date(element.tglregistrasi)
            ).format("YYYY-MM-DD");
          }
          let datas = [];
          if (response.daftar.length > 0) {
            datas = response.daftar;
            datas.sort(this.compareRiwaayat);
          }
          this.dataSourceRm = datas;
        },
        (error) => {
          this.dataSourceRm = [];
        }
      );
  }
  compareRiwaayat(a, b) {
    if (a.tglregistrasi > b.tglregistrasi) {
      return -1;
    }
    if (a.tglregistrasi < b.tglregistrasi) {
      return 1;
    }
    return 0;
  }
  // copyNorek() {
  //   if (this.selectedNorek == undefined) {
  //     this.messageService.warn('Peringatan', 'Pilih data dulu')
  //     return
  //   }
  //   let selec = this.selectedNorek.bankaccountnomor
  //   selec.select()
  //   document.execCommand('copy');
  //   alert("Copied the text: " + selec.value);
  // }
  // copyInputMessage(inputElement) {
  //   inputElement.select();
  //   document.execCommand('copy');
  //   inputElement.setSelectionRange(0, 0);
  // }
  onRowSelectNoRek(event) {
    this.selectedNorek = event.data;
  }
  clickPanduan() {
    this.isPanduanReservasi = !this.isPanduanReservasi;
  }
}
export interface ImageViewerConfig {
  btnClass?: string;
  zoomFactor?: number;
  containerBackgroundColor?: string;
  wheelZoom?: boolean;
  allowFullscreen?: boolean;
  allowKeyboardNavigation?: boolean;

  btnShow?: {
    zoomIn?: boolean;
    zoomOut?: boolean;
    rotateClockwise?: boolean;
    rotateCounterClockwise?: boolean;
    next?: boolean;
    prev?: boolean;
  };

  btnIcons?: {
    zoomIn?: string;
    zoomOut?: string;
    rotateClockwise?: string;
    rotateCounterClockwise?: string;
    next?: string;
    prev?: string;
    fullscreen?: string;
  };

  customBtns?: Array<
    {
      name: string;
      icon: string;
    }
  >;
}

export class CustomEvent {
  name: string;
  imageIndex: number;

  constructor(name, imageIndex) {
    this.name = name;
    this.imageIndex = imageIndex;
  }
}



